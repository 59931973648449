import { Component } from 'react';
import { connect } from 'react-redux';
import { controlModes } from '../../state/reducers/editor/controlMode';

class ControlModeRotationHandler extends Component {
  componentDidUpdate(prevProps) {
    if (
      prevProps.controlMode === controlModes.CAMERA
      && this.props.controlMode === controlModes.CROP
    ) {
      this.animateRotationReset();
    }
  }

  animateRotationReset() {
    const { x, y } = this.props.rotation;
    let time = 0;
    let then = performance.now();

    const speed = 1.5;
    const a = 2;
    const animate = t => ((-(t ** a) / ((t ** a) + ((1 - t) ** 2))) + 1);
    const animateX = t => animate(t) * x;
    const animateY = t => animate(t) * y;

    const rotate = (now) => {
      const deltaTime = Math.max(now - then, 0.0);
      then = now;
      time += deltaTime * 0.001 * speed;

      this.props.dispatch({
        type: 'ROTATE_TO',
        x: animateX(time),
        y: animateY(time),
      });

      if (time < 1) {
        requestAnimationFrame(rotate);
      }
    };

    requestAnimationFrame(rotate);
  }

  render() {
    return null;
  }
}

const mapStateToProps = state => ({
  controlMode: state.controlMode,
  rotation: state.rotation,
});

export default connect(mapStateToProps)(ControlModeRotationHandler);
