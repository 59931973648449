import React from 'react';
import classNames from 'classnames';

function SidebarSection(props) {
  const className = classNames('Sidebar__section', props.className);

  return (
    <section className={className}>
      {props.children}
    </section>
  );
}

SidebarSection.defaultProps = {
  className: '',
};

export default SidebarSection;
