import React, { useEffect } from 'react';
import { useWindowSize, useMedia } from 'react-use';
import { connect } from 'react-redux';
import classNames from 'classnames';
import useSceneRendering from '../../hooks/useSceneRendering';
import ActivityIndicator from '../utility/ActivityIndicator';
import './GLCanvas.scss';

function GLCanvas(props) {
  const canvas = React.createRef();
  const isDesktop = useMedia('(min-width: 920px)', true);

  const { width, height } = useWindowSize();
  const {
    isLoading,
    refresh,
    isRefreshing,
  } = useSceneRendering(canvas, props.config);

  useEffect(() => {
    refresh();
  }, [isDesktop && props.isBesideCheckout, width, height]);

  return (
    <>
      {(isLoading || isRefreshing) && (
        <div className="GLCanvas__loading">
          <ActivityIndicator size={2} />
          {isLoading && (
            <span>Vorschau wird geladen</span>
          )}
        </div>
      )}
      <canvas
        ref={canvas}
        id="GLCanvas"
        className={classNames('GLCanvas__canvas', {
          'GLCanvas__canvas--initialized': !isLoading,
          'GLCanvas__canvas--reloading': isRefreshing,
        })}
        width={props.isBesideCheckout && isDesktop ? width - 400 : width}
        height={height - 46}
      />
    </>
  );
}

const mapStateToProps = state => ({
  config: {
    image: state.images.items[state.images.active],
    render: {
      controlMode: state.controlMode,
      zoom: state.zoom,
      rotation: state.rotation,
    },
  },
});

export default connect(mapStateToProps)(GLCanvas);
