import React, { useState } from 'react';
import { connect } from 'react-redux';
import { IoIosCart } from 'react-icons/io';
import { MdCheck } from 'react-icons/md';
import classNames from 'classnames';
import ButtonLink from '../utility/buttons/ButtonLink';
import Button from '../utility/buttons/Button';
import { controlModes } from '../../state/reducers/editor/controlMode';
import EditorControlsButton from './EditorControlsButton';
import controls from './controls';
import './EditorControls.scss';

function EditorControls(props) {
  const [activeControl, setActiveControl] = useState(null);

  function switchToCameraMode() {
    props.dispatch({
      type: 'SET_CONTROL_MODE',
      mode: controlModes.CAMERA,
    });
  }

  function handleCloseControl() {
    setActiveControl(null);
    switchToCameraMode();
  }

  function handleControlClick(key) {
    setActiveControl(key);

    switch (key) {
      case 'crop':
        props.dispatch({
          type: 'SET_CONTROL_MODE',
          mode: controlModes.CROP,
        });
        break;
      default:
        switchToCameraMode();
        break;
    }
  }

  const activeControlConfig = activeControl && controls[activeControl];
  const className = classNames('OverlayControls', {
    'OverlayControls--has-active': !!activeControl,
  });

  return (
    <div className={className}>
      {activeControlConfig && activeControlConfig.Component && (
        <div className="OverlayControls__control">
          <activeControlConfig.Component />
        </div>
      )}
      <div className="OverlayControls__buttons">
        <div className="OverlayControls__buttons__left">
          {Object.keys(controls).map(key => (
            <EditorControlsButton
              title={controls[key].title}
              Icon={controls[key].Icon}
              onClick={() => handleControlClick(key)}
              isActive={activeControl === key}
            />
          ))}
        </div>
        <div className="OverlayControls__buttons__right">
          {activeControl ? (
            <Button
              className="OverlayControls__submit-button"
              onClick={handleCloseControl}
            >
              <MdCheck style={{ fontSize: 'var(--font-size-5)' }} />
              <span>Anwenden</span>
            </Button>
          ) : (
            <ButtonLink
              to="/checkout"
              className="OverlayControls__submit-button"
              disabled={!props.hasActiveImage}
            >
              <IoIosCart style={{ fontSize: 'var(--font-size-5)' }} />
              <span>Zur Bestellung</span>
            </ButtonLink>
          )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  hasActiveImage: !state.images.isDefault,
});

export default connect(mapStateToProps)(EditorControls);
