import React, { useState } from 'react';
import queryString from 'query-string';
import { Redirect } from 'react-router-dom';
import ActivityIndicator from '../utility/ActivityIndicator';
import Confirmation from './Confirmation';
import useStripeJs from '../../hooks/useStripeJs';
import './Confirmation.scss';

const messages = {
  canceled: `
    Sie haben die Bezahlung abgebrochen.
    Aber keine Sorge, Sie können es jederzeit erneut versuchen.
  `,
  failed: `
    Bei dem Bezahl-Vorgang ist ein Fehler aufgetreten.
    Bitte versuchen Sie es erneut.
  `,
};

function StripeSourceConfirmation(props) {
  const [source, setSource] = useState(null);
  const [error, setError] = useState(null);

  useStripeJs(async (stripe) => {
    const { location } = props;
    const parsed = queryString.parse(location.search);

    const result = await stripe.retrieveSource({
      id: parsed.source,
      client_secret: parsed.client_secret,
    });

    setSource(result.source);
    setError(result.error);
  });

  if (!source && !error) {
    return (
      <div className="Confirmation__loading">
        <ActivityIndicator size={2} />
        <span>Wird geprüft...</span>
      </div>
    );
  }

  if (['failed', 'canceled'].includes(source.status)) {
    return (
      <Redirect
        to={{
          pathname: '/checkout',
          state: { error: messages[source.status] },
        }}
      />
    );
  }

  return (
    <Confirmation
      name={source.owner.name}
      email={source.owner.email}
    />
  );
}

export default StripeSourceConfirmation;
