const MIN_ZOOM = 10;
const MAX_ZOOM = 200;
export const STEP = 5;

const initialState = 100;

function zoom(state = initialState, action) {
  switch (action.type) {
    case 'ZOOM_IN':
      return Math.min(state + STEP, MAX_ZOOM);
    case 'ZOOM_OUT':
      return Math.max(state - STEP, MIN_ZOOM);
    case 'ZOOM_IN_BY':
      return Math.min(state + action.step, MAX_ZOOM);
    case 'ZOOM_OUT_BY':
      return Math.max(state - action.step, MIN_ZOOM);
    default:
      return state;
  }
}

export default zoom;
