import { vec2 } from 'gl-matrix';
import createShader from '../helper/createShader';
import createProgram from '../helper/createProgram';
import squareXY from '../geometry/squareXY';

class ImageDisplacer {
  constructor(gl, image) {
    this.gl = gl;
    this.width = image.width;
  }

  async init() {
    this.loadGeometry();
    await this.loadProgram();
  }

  freeResources() {
    const { gl, programInfo: { program } } = this;

    gl.getAttachedShaders(program).forEach((shader) => {
      gl.deleteShader(shader);
    });

    gl.deleteProgram(program);
  }

  async loadProgram() {
    const { gl } = this;
    const vertexShader = await createShader(gl, gl.VERTEX_SHADER, 'shader/image_displacement.vert');
    const fragmentShader = await createShader(gl, gl.FRAGMENT_SHADER, 'shader/image_displacement.frag');
    const program = createProgram(gl, fragmentShader, vertexShader);

    this.programInfo = {
      program,
      attribLocations: {
        vertexPosition: gl.getAttribLocation(program, 'aVertexPosition'),
      },
      uniformLocations: {
        image: gl.getUniformLocation(program, 'uImage'),
        position: gl.getUniformLocation(program, 'uPosition'),
        zoom: gl.getUniformLocation(program, 'uZoom'),
        innerAspectRatio: gl.getUniformLocation(program, 'uInnerAspectRatio'),
        imageAspectRatio: gl.getUniformLocation(program, 'uImageAspectRatio'),
      },
    };
  }

  loadGeometry() {
    const { gl } = this;
    this.vertexBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, this.vertexBuffer);
    gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(squareXY.vertices), gl.STATIC_DRAW);
  }

  render(inTexture, outFramebuffer, imageConfig) {
    const { gl } = this;
    const { program, attribLocations, uniformLocations } = this.programInfo;
    gl.useProgram(program);

    gl.activeTexture(gl.TEXTURE0);
    gl.bindTexture(gl.TEXTURE_2D, inTexture);

    // uniforms

    gl.uniform1f(uniformLocations.innerAspectRatio, imageConfig.innerAspectRatio);
    gl.uniform1f(uniformLocations.imageAspectRatio, imageConfig.aspectRatio);
    gl.uniform1f(uniformLocations.zoom, imageConfig.zoom);
    gl.uniform2fv(uniformLocations.position, vec2.fromValues(imageConfig.position.x, imageConfig.position.y));
    gl.uniform1i(uniformLocations.image, 0);

    // draw

    gl.bindBuffer(gl.ARRAY_BUFFER, this.vertexBuffer);
    gl.vertexAttribPointer(attribLocations.vertexPosition, 3, gl.FLOAT, false, 0, 0);
    gl.enableVertexAttribArray(attribLocations.vertexPosition);

    gl.bindFramebuffer(gl.FRAMEBUFFER, outFramebuffer);
    gl.viewport(0, 0, this.width, this.width / imageConfig.innerAspectRatio);
    gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4);
    gl.viewport(0, 0, gl.canvas.width, gl.canvas.height);
  }
}

export default ImageDisplacer;
