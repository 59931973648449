import {
  IoIosContrast,
  IoIosSunny,
  IoIosCrop,
  IoIosQrScanner,
} from 'react-icons/io';
import ContrastSlider from './ContrastSlider';
import BrightnessSlider from './BrightnessSlider';
import FormatSelector from './FormatSelector';

export default {
  aspectRatio: {
    title: 'Format',
    Icon: IoIosQrScanner,
    Component: FormatSelector,
  },
  crop: {
    title: 'Ausrichten',
    Icon: IoIosCrop,
  },
  contrast: {
    title: 'Kontrast',
    Icon: IoIosContrast,
    Component: ContrastSlider,
  },
  brightness: {
    title: 'Helligkeit',
    Icon: IoIosSunny,
    Component: BrightnessSlider,
  },
};
