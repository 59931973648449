import React from 'react';
import classNames from 'classnames';
import './Button.scss';

function Button({
  children,
  type,
  className,
  size,
  disabled,
  white,
  plain,
  rounded,
  ...rest
}) {
  const styleClasses = classNames('Button', `-size-${size}`, {
    '-disabled': disabled,
    '-white': white,
    '-plain': plain,
    '-rounded': rounded,
  }, className);

  return (
    <button
      {...rest}
      className={styleClasses}
      type={type}
      disabled={disabled}
    >
      {children}
    </button>
  );
}

Button.defaultProps = {
  size: 1,
  type: 'button',
  className: '',
};

export default Button;
