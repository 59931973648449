import range from 'lodash/range';
import Gravure from './Gravure';
import DropShadow from './DropShadow';
import ImageProcessor from './image_processing/ImageProcessor';
import CropPreview from './CropPreview';
import { controlModes } from '../state/reducers/editor/controlMode';
import loadImage from './helper/loadImage';
import createTexture from './helper/createTexture';
// import TextureDebug from './TextureDebug';

class Scene {
  constructor(gl) {
    this.gl = gl;
    this.gravure = new Gravure(gl);
    this.cropPreview = new CropPreview(gl);
    this.dropShadow = new DropShadow(gl);
    // this.textureDebug = new TextureDebug(gl);
    this.imageProcessor = new ImageProcessor(gl);
    this.lastImageConfig = null;
    this.textures = {};
  }

  freeResources() {
    const { gl } = this;

    this.textures.woodAtlases.forEach((atlas) => {
      gl.deleteTexture(atlas);
    });

    this.gravure.freeResources();
    this.dropShadow.freeResources();
    this.cropPreview.freeResources();
    this.imageProcessor.freeResources();
  }

  async loadWoodTexture() {
    this.textures.woodAtlases = [];

    const paths = range(1).map(i => `resources/textures/birch/birch_atlas_1x8_${i}.jpg`);

    await Promise.all(paths.map(async (path, i) => {
      const image = await loadImage(path);
      this.textures.woodAtlases[i] = createTexture(this.gl, image);
    }));
  }

  async init() {
    await Promise.all([
      this.loadWoodTexture(),
      this.gravure.init(),
      this.dropShadow.init(),
      this.cropPreview.init(),
      // this.textureDebug.init(),
    ]);
  }

  async setup(imageConfig) {
    if (!this.lastImageConfig || this.lastImageConfig.src !== imageConfig.src) {
      await this.imageProcessor.init(imageConfig.src);
    }

    this.imageProcessor.render(imageConfig);
    this.lastConfig = imageConfig;
  }

  render(renderConfig, imageConfig) {
    const { gl } = this;
    gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);

    const textures = {
      ...this.imageProcessor.textures,
      ...this.textures,
    };

    this.gravure.render(textures, renderConfig, imageConfig);
    this.dropShadow.render(renderConfig, imageConfig);

    if (renderConfig.controlMode === controlModes.CROP) {
      this.cropPreview.render(textures.image, renderConfig, imageConfig);
    }

    // this.textureDebug.render(textures.heightMap);
  }
}

export default Scene;
