import { combineReducers } from 'redux';
import images from './reducers/images/images';
import zoom from './reducers/editor/zoom';
import rotation from './reducers/editor/rotation';
import controlMode from './reducers/editor/controlMode';
import discount from './reducers/discount';
import checkoutForm from './reducers/checkoutForm';
import instagram from './reducers/instagram';

const rootReducer = combineReducers({
  zoom,
  images,
  rotation,
  controlMode,
  discount,
  checkoutForm,
  instagram,
});

export default rootReducer;
