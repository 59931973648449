import React, { Component } from 'react';
import { connect } from 'react-redux';
import Slider from 'rc-slider';
import {
  MAX_BRIGHTNESS,
  MIN_BRIGHTNESS,
  BRIGHTNESS_STEP,
} from '../../state/reducers/images/config';
import 'rc-slider/assets/index.css';

class BrightnessSlider extends Component {
  handleChange = (value) => {
    this.props.dispatch({
      type: 'SET_BRIGHTNESS',
      value,
    });
  };

  render() {
    const { brightness } = this.props;

    return (
      <div>
        <Slider
          max={MAX_BRIGHTNESS}
          min={MIN_BRIGHTNESS}
          step={BRIGHTNESS_STEP}
          value={brightness}
          onChange={this.handleChange}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  brightness: state.images.items[state.images.active].brightness,
});

export default connect(mapStateToProps)(BrightnessSlider);
