const PRICING = {
  base: 12.95,
  subsequent: 7.99,
};

export function getTotalPrize(items) {
  if (items.length === 0) return 0;

  // base price
  let total = PRICING.base;

  // subsequent price
  total += (items.length - 1) * PRICING.subsequent;

  return total;
}

export default PRICING;
