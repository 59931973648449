import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import Button from '../utility/buttons/Button';

function FormatSelectorItem(props) {
  function handleClick() {
    if (props.isActive) return;

    props.dispatch({
      type: 'SET_ASPECT_RATIO',
      width: props.format.width,
      height: props.format.height,
    });
  }

  const dimension = `${props.format.width} cm x ${props.format.height} cm`;

  return (
    <Button
      plain
      onClick={handleClick}
      className={classNames('FormatSelector__item', {
        '-active': props.isActive,
      })}
    >
      <div className="FormatSelector__item__image-container">
        <img
          className="FormatSelector__item__image"
          src={props.format.image}
          alt={dimension}
        />
        {props.isActive && (
          <span className="FormatSelector__item__active-indicator">
            <IoIosCheckmarkCircle />
          </span>
        )}
      </div>
      <span className="FormatSelector__item__info">
        {dimension}
      </span>
    </Button>
  );
}

const mapStateToProps = (state, ownProps) => {
  const { frameAspectRatio } = state.images.items[state.images.active];

  return {
    isActive: frameAspectRatio === ownProps.format.width / ownProps.format.height,
  };
};

export default connect(mapStateToProps)(FormatSelectorItem);
