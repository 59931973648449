import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { IoMdCloudUpload } from 'react-icons/io';
import Button from '../../utility/buttons/Button';
import loadImage from '../../../rendering/helper/loadImage';
import preProcessImage from './preProcessImage';


function ComputerImportButton(props) {
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onload = async ({ target }) => {
        const image = await loadImage(target.result);
        const { width, height, src } = preProcessImage(image);

        props.dispatch({
          type: 'ADD_IMAGE',
          src,
          width,
          height,
        });
      };
      reader.readAsDataURL(file);
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: true,
    accept: 'image/*',
  });

  return (
    <Button
      className={classNames('ImportControls__button', {
        '-new': !props.hasActiveImage,
        '-change': props.hasActiveImage,
      })}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <IoMdCloudUpload style={{ fontSize: 'var(--font-size-4)' }} />
      <span className="ImportControls__button__device-label" />
    </Button>
  );
}

const mapStateToProps = state => ({
  hasActiveImage: !state.images.isDefault,
});

export default connect(mapStateToProps)(ComputerImportButton);
