import createTexture from '../helper/createTexture';
import loadImage from '../helper/loadImage';
import ImageToHeightMap from './ImageToHeightMap';
import ImageDisplacer from './ImageDisplacer';

class ImageProcessor {
  constructor(gl) {
    this.gl = gl;
    this.image = {};
    this.textures = {};
    this.framebuffers = {};
    this.currentConfig = {};
  }

  freeResources() {
    const { gl } = this;

    Object.keys(this.textures).forEach((key) => {
      gl.deleteTexture(this.textures[key]);
    });

    Object.keys(this.framebuffers).forEach((key) => {
      gl.deleteFramebuffer(this.framebuffers[key]);
    });

    this.getPipelineItems().forEach((item) => {
      item.freeResources();
    });
  }

  extractImage(): string {
    const { width } = this.image;
    const height = Math.ceil(width / this.currentConfig.innerAspectRatio);

    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;

    const ctx = canvas.getContext('2d');
    const imageData = ctx.createImageData(width, height);
    const { pixels } = this.pipeline.imageToHeightMap;
    imageData.data.set(pixels);
    ctx.putImageData(imageData, 0, 0);

    const dataURL = canvas.toDataURL('image/png');
    return dataURL;
  }

  async init(imageSrc) {
    this.image = await loadImage(imageSrc);
    this.textures.image = await createTexture(this.gl, this.image);

    this.pipeline = {
      imageDisplacer: new ImageDisplacer(this.gl, this.image),
      imageToHeightMap: new ImageToHeightMap(this.gl, this.image),
    };

    await Promise.all(this.getPipelineItems().map(item => item.init()));
  }

  getPipelineItems() {
    return Object.keys(this.pipeline).map(key => this.pipeline[key]);
  }

  configureTexture(key) {
    const { gl, image: { width } } = this;
    const height = width / this.currentConfig.innerAspectRatio;
    const texture = gl.createTexture();
    gl.bindTexture(gl.TEXTURE_2D, texture);
    gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, width, height, 0, gl.RGBA, gl.UNSIGNED_BYTE, null);

    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);

    if (this.textures[key]) {
      gl.deleteTexture(this.textures[key]);
    }

    this.textures[key] = texture;
  }

  configureFramebuffer(key) {
    const { gl } = this;
    const framebuffer = gl.createFramebuffer();
    gl.bindFramebuffer(gl.FRAMEBUFFER, framebuffer);
    gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, this.textures[key], 0);

    if (this.framebuffers[key]) {
      gl.deleteFramebuffer(this.framebuffers[key]);
    }

    this.framebuffers[key] = framebuffer;
  }

  render(config) {
    const { gl } = this;
    this.currentConfig = config;

    this.configureTexture('displacedImage');
    this.configureFramebuffer('displacedImage');
    this.pipeline.imageDisplacer.render(this.textures.image, this.framebuffers.displacedImage, config);

    this.configureTexture('heightMap');
    this.configureFramebuffer('heightMap');
    this.pipeline.imageToHeightMap.render(this.textures.displacedImage, this.framebuffers.heightMap, config);

    // clean up for other rendering

    gl.bindTexture(gl.TEXTURE_2D, null);
    gl.bindFramebuffer(gl.FRAMEBUFFER, null);
    gl.viewport(0, 0, gl.canvas.width, gl.canvas.height);
  }
}

export default ImageProcessor;
