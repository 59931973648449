import { useEffect, useState } from 'react';
import setupGl from '../rendering/helper/setupGl';
import Scene from '../rendering/Scene';

let scene;
let renderConfig;
let imageConfig;

let animationRequest;

function useSceneRendering(canvas, config) {
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isInitiated, setIsInitiated] = useState(false);
  const [isSettingUp, setIsSettingUp] = useState(false);

  const refresh = async () => {
    setIsRefreshing(true);
    if (isInitiated && !isSettingUp) {
      setIsSettingUp(true);
      await scene.setup(config.image);
      setIsSettingUp(false);
    }
    setIsRefreshing(false);
  };

  renderConfig = config.render;
  imageConfig = config.image;

  const render = () => {
    scene.render(renderConfig, imageConfig);
    animationRequest = requestAnimationFrame(render);
  };

  const init = async () => {
    const gl = canvas.current.getContext('webgl', { preserveDrawingBuffer: true });
    setupGl(gl);

    scene = new Scene(gl);
    await scene.init();
    await scene.setup(config.image);

    setIsInitiated(true);

    render();
  };

  const main = async () => {
    if (!isInitiated) {
      await init();
      return;
    }

    if (!isSettingUp) {
      setIsSettingUp(true);
      await scene.setup(config.image);
      setIsSettingUp(false);
    }
  };

  function unmount() {
    cancelAnimationFrame(animationRequest);
    animationRequest = undefined;
    scene.freeResources();
  }

  useEffect(() => {
    main();
  }, [...Object.values(config.image)]);

  useEffect(() => unmount, []);

  return {
    refresh,
    isRefreshing,
    isLoading: !isInitiated,
    extractProcessedImages: scene
      ? () => scene.imageProcessor.extractImage()
      : () => {},
  };
}

export default useSceneRendering;
