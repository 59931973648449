import { useState } from 'react';

function useModal() {
  const [isOpen, setIsOpen] = useState(false);

  function open() {
    setIsOpen(true);
  }

  function close() {
    setIsOpen(false);
  }

  return {
    isModalOpen: isOpen,
    openModal: open,
    closeModal: close,
  };
}

export default useModal;
