import React from 'react';
import './HomeFooter.scss';

function HomeFooter() {
  return (
    <footer className="HomeFooter">
      <span className="HomeFooter__copyright">
        Gravier dein Bild &copy;
      </span>
      <menu>
        <span>Links</span>
        <ul>
          <li>
            <a href="/static/imprint.html">
              Impressum
            </a>
          </li>
          <li>
            <a href="/static/privacy.html">
              Datenschutz
            </a>
          </li>
          <li>
            <a href="/static/agb.html">
              AGB
            </a>
          </li>
        </ul>
      </menu>
    </footer>
  );
}

export default HomeFooter;
