import axios from 'axios';
import queryString from 'query-string';
import humps from 'humps';

class InstagramService {
  static async authorize() {
    const url = 'https://api.instagram.com/oauth/authorize/';

    const { origin, pathname } = window.location;
    const params = queryString.stringify({
      client_id: process.env.REACT_APP_INSTAGRAM_CLIENT_ID,
      redirect_uri: `${origin}${pathname}`,
      response_type: 'token',
    });

    window.location = `${url}?${params}`;
  }

  static async getUserMedia(accessToken) {
    const params = humps.decamelizeKeys({ accessToken });
    const url = 'https://api.instagram.com/v1/users/self/media/recent/';
    const response = await axios.get(url, { params });

    return response.data.data;
  }
}

export default InstagramService;
