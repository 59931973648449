import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { FaInstagram } from 'react-icons/fa';
import Button from '../../utility/buttons/Button';
import InstagramSelectionModal from '../instagram/InstagramSelectionModal';
import InstagramService from '../../../services/InstagramService';
import useModal from '../../utility/useModal';

function useInstagramMedia(accessToken) {
  const [images, setImages] = useState([]);

  useEffect(() => {
    if (accessToken) {
      (async () => {
        try {
          const media = await InstagramService.getUserMedia(accessToken);
          setImages(media);
        } catch (error) {
          // catch error
        }
      })();
    }
  }, [accessToken]);

  return images;
}

function InstagramImport(props) {
  const accessToken = useMemo(() => {
    if (!props.location.hash) return null;

    const token = props.location.hash.split('=')[1];
    return token || null;
  }, [props.location.hash]);

  const { isModalOpen, openModal, closeModal } = useModal();
  const images = useInstagramMedia(accessToken, () => {
    if (!props.image) {
      openModal();
    }
  });

  useEffect(() => {
    if (!props.isAuthorized && accessToken) {
      openModal();
    }

    props.dispatch({
      type: 'SET_INSTAGRAM_CONFIG',
      isAuthorized: !!accessToken,
    });
  }, []);

  function handleClick() {
    if (accessToken) {
      openModal();
    } else {
      InstagramService.authorize();
    }
  }

  return (
    <>
      {(images.length > 0) && (
        <InstagramSelectionModal
          images={images}
          isOpen={isModalOpen}
          onClose={closeModal}
        />
      )}
      <Button
        className={classNames('ImportControls__button', {
          '-change': !!props.hasActiveImage,
          '-new': !props.hasActiveImage,
        })}
        onClick={handleClick}
      >
        <FaInstagram style={{ fontSize: 'var(--font-size-4)' }} />
        <span>Instagram</span>
      </Button>
    </>
  );
}

const mapStateToProps = state => ({
  hasActiveImage: !state.images.isDefault,
  isAuthorized: state.instagram.isAuthorized,
});

export default connect(mapStateToProps)(withRouter(InstagramImport));
