import { glMatrix, mat4, vec3 } from 'gl-matrix';
import createShader from './helper/createShader';
import createProgram from './helper/createProgram';
import squareXY from './geometry/squareXY';
import cubeGeometry from './geometry/normalCube';
import createViewProjectionMatrix from './helper/createViewProjectionMatrix';
import { DEFAULT_BORDER } from '../state/reducers/images/config';

class CropPreview {
  constructor(gl) {
    this.gl = gl;
    this.framebuffer = null;
  }

  async init() {
    this.loadHoleGeometry();
    this.loadGeometry();

    await Promise.all([
      this.loadProgram(),
      this.loadHoleProgram(),
      this.loadHoleGeometry(),
    ]);
  }

  freeResources() {
    const { gl } = this;
    gl.deleteFramebuffer(this.framebuffer);
    gl.deleteProgram(this.programInfo.program);
  }

  async loadHoleProgram() {
    const { gl } = this;
    const vertexShader = await createShader(gl, gl.VERTEX_SHADER, 'shader/crop_hole.vert');
    const fragmentShader = await createShader(gl, gl.FRAGMENT_SHADER, 'shader/crop_hole.frag');
    const program = createProgram(gl, fragmentShader, vertexShader);

    this.holeProgramInfo = {
      program,
      attribLocations: {
        vertexPosition: gl.getAttribLocation(program, 'aVertexPosition'),
      },
      uniformLocations: {
        modelViewProjectionMatrix: gl.getUniformLocation(program, 'uMVP'),
        aspectRatio: gl.getUniformLocation(program, 'uAspectRatio'),
        innerAspectRatio: gl.getUniformLocation(program, 'uInnerAspectRatio'),
        border: gl.getUniformLocation(program, 'uBorder'),
      },
    };
  }

  loadGeometry() {
    const { gl } = this;

    this.vertexBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, this.vertexBuffer);
    gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(squareXY.vertices), gl.STATIC_DRAW);
  }

  loadHoleGeometry() {
    const { gl } = this;

    this.holeVertexBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, this.holeVertexBuffer);
    gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(cubeGeometry.vertices), gl.STATIC_DRAW);

    this.holeIndexBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, this.holeIndexBuffer);
    gl.bufferData(gl.ELEMENT_ARRAY_BUFFER, new Uint16Array(cubeGeometry.indices), gl.STATIC_DRAW);
  }

  async loadProgram() {
    const { gl } = this;
    const vertexShader = await createShader(gl, gl.VERTEX_SHADER, 'shader/crop.vert');
    const fragmentShader = await createShader(gl, gl.FRAGMENT_SHADER, 'shader/crop.frag');
    const program = createProgram(gl, fragmentShader, vertexShader);

    this.programInfo = {
      program,
      attribLocations: {
        vertexPosition: gl.getAttribLocation(program, 'aVertexPosition'),
      },
      uniformLocations: {
        modelViewProjectionMatrix: gl.getUniformLocation(program, 'uMVP'),
        image: gl.getUniformLocation(program, 'uImage'),
        aspectRatio: gl.getUniformLocation(program, 'uAspectRatio'),
        innerAspectRatio: gl.getUniformLocation(program, 'uInnerAspectRatio'),
        border: gl.getUniformLocation(program, 'uBorder'),
        imageAspectRatio: gl.getUniformLocation(program, 'uImageAspectRatio'),
      },
    };
  }

  drawHole(viewProjectionMatrix, modelMatrix, imageConfig) {
    const MVP = mat4.create();
    mat4.multiply(MVP, viewProjectionMatrix, modelMatrix);

    const { gl } = this;
    const { program, attribLocations, uniformLocations } = this.holeProgramInfo;
    gl.useProgram(program);

    gl.enable(gl.STENCIL_TEST);
    gl.stencilFunc(gl.ALWAYS, 1, 0xFF);
    gl.stencilMask(0xFF);
    gl.colorMask(false, false, false, false);

    gl.bindBuffer(gl.ARRAY_BUFFER, this.holeVertexBuffer);
    gl.vertexAttribPointer(attribLocations.vertexPosition, 3, gl.FLOAT, false, 0, 0);
    gl.enableVertexAttribArray(attribLocations.vertexPosition);

    gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, this.holeIndexBuffer);

    gl.uniform1f(uniformLocations.innerAspectRatio, imageConfig.innerAspectRatio);
    gl.uniform1f(uniformLocations.aspectRatio, imageConfig.frameAspectRatio);
    gl.uniform1f(uniformLocations.border, DEFAULT_BORDER);
    gl.uniformMatrix4fv(uniformLocations.modelViewProjectionMatrix, false, MVP);

    gl.drawElements(gl.TRIANGLES, 36, gl.UNSIGNED_SHORT, 0);
  }

  drawCropPreview(viewProjectionMatrix, modelMatrix, imageTexture, imageConfig) {
    const { gl } = this;

    const { program, attribLocations, uniformLocations } = this.programInfo;
    gl.useProgram(program);

    gl.stencilFunc(gl.NOTEQUAL, 1, 0xFF);
    gl.stencilMask(0x00);
    gl.colorMask(true, true, true, true);

    const { x, y } = imageConfig.position;
    const scale = imageConfig.zoom;
    const ifAR = imageConfig.innerAspectRatio; // inner frame aspect ratio (without border)
    const iAR = imageConfig.aspectRatio; // image aspect ratio

    const ofW = (2.0 * imageConfig.frameAspectRatio); // outer frame width (with border)
    const ofH = 2.0; // outer frame height (with border)

    const ifW = ofW - 2.0 * ofH * DEFAULT_BORDER; // inner frame width (without border)
    const ifH = ofH - 2.0 * ofH * DEFAULT_BORDER; // inner frame height (without border)

    const iW = scale * ifW; // image width (scaled inner frame width)
    const iH = scale * ifW / iAR; // image height (scaled inner frame * image aspect ratio)

    // x translation: -(iW - ifW) / 2.0 -> (iW - ifW) / 2.0 (if x is between [0,1])
    const nX = (iW - ifW) * 0.5;
    const mX = (iW - ifW) * 0.5 * -1.0 - nX;
    // the calculation with min_x is to transform x to a value between [0,1]
    // it reverses the operations in the image displacement reducer
    const minX = (1.0 - 1.0 * scale);
    const translateX = (minX !== 0 ? x / minX : 0) * mX + nX;

    // y translation: (iH - ifH) / 2.0 -> -(iH - ifH) / 2.0 (if y is between [0,1])
    const nY = (iH - ifH) * 0.5 * -1.0;
    const mY = (iH - ifH) * 0.5 - nY;
    // same for y as above
    const minY = (1.0 / ifAR - (1.0 * scale) / iAR) * ifAR * iAR;
    const translateY = (minY !== 0 ? y / minY : 0) * mY + nY;

    mat4.translate(modelMatrix, modelMatrix, vec3.fromValues(translateX, translateY, 0.0));
    mat4.scale(modelMatrix, modelMatrix, vec3.fromValues(scale, scale, 1.0));

    const MVP = mat4.create();
    mat4.multiply(MVP, viewProjectionMatrix, modelMatrix);

    gl.bindBuffer(gl.ARRAY_BUFFER, this.vertexBuffer);
    gl.vertexAttribPointer(attribLocations.vertexPosition, 3, gl.FLOAT, false, 0, 0);
    gl.enableVertexAttribArray(attribLocations.vertexPosition);

    gl.activeTexture(gl.TEXTURE0);
    gl.bindTexture(gl.TEXTURE_2D, imageTexture);

    gl.uniform1i(uniformLocations.texture, 0);
    gl.uniform1f(uniformLocations.innerAspectRatio, imageConfig.innerAspectRatio);
    gl.uniform1f(uniformLocations.aspectRatio, imageConfig.frameAspectRatio);
    gl.uniform1f(uniformLocations.border, DEFAULT_BORDER);
    gl.uniform1f(uniformLocations.imageAspectRatio, imageConfig.aspectRatio);

    gl.uniformMatrix4fv(uniformLocations.modelViewProjectionMatrix, false, MVP);

    gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4);

    gl.stencilMask(0xFF);
    gl.disable(gl.STENCIL_TEST);
  }

  render(imageTexture, renderConfig, imageConfig) {
    if (!imageTexture) throw new Error('Texture is missing.');

    const { gl } = this;

    const { viewProjectionMatrix } = createViewProjectionMatrix(gl, renderConfig);

    const modelMatrix = mat4.create();
    mat4.rotate(modelMatrix, modelMatrix, glMatrix.toRadian(renderConfig.rotation.x), [0.0, 1.0, 0.0]);
    mat4.rotate(modelMatrix, modelMatrix, glMatrix.toRadian(renderConfig.rotation.y), [1.0, 0.0, 0.0]);

    this.drawHole(viewProjectionMatrix, modelMatrix, imageConfig);
    this.drawCropPreview(viewProjectionMatrix, modelMatrix, imageTexture, imageConfig);
  }
}

export default CropPreview;
