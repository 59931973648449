import React from 'react';
import { MdClose } from 'react-icons/md';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Button from '../../utility/buttons/Button';

function ImagesListItem(props) {
  function onClick() {
    props.dispatch({
      type: 'SET_ACTIVE_IMAGE',
      index: props.index,
    });
  }

  function onRemove() {
    props.dispatch({
      type: 'REMOVE_IMAGE',
      index: props.index,
    });
  }

  return (
    <div
      className={classNames('ImportControls__list__item', {
        '-active': props.isActive,
      })}
    >
      <Button
        plain
        onClick={onRemove}
        className="ImportControls__list__item__remove-button"
      >
        <MdClose />
      </Button>
      <Button
        plain
        className="ImportControls__list__item__button-overlay"
        onClick={onClick}
      />
      <img
        className="ImportControls__list__item__image"
        src={props.image.src}
        alt="Hochgeladenes Bild"
      />
    </div>
  );
}

const mapStateToProps = (state, ownProps) => ({
  isActive: state.images.active === ownProps.index,
});

export default connect(mapStateToProps)(ImagesListItem);
