import { mat4, glMatrix } from 'gl-matrix';
import createShader from './helper/createShader';
import createProgram from './helper/createProgram';
import squareXY from './geometry/squareXY';
import createViewProjectionMatrix from './helper/createViewProjectionMatrix';

class DropShadow {
  constructor(gl) {
    this.gl = gl;
  }

  async init() {
    await this.loadProgram();
  }

  freeResources() {
    const { gl, programInfo: { program } } = this;

    gl.getAttachedShaders(program).forEach((shader) => {
      gl.deleteShader(shader);
    });

    gl.deleteProgram(program);
  }

  async loadProgram() {
    const { gl } = this;
    const vertexShader = await createShader(gl, gl.VERTEX_SHADER, 'shader/dropshadow.vert');
    const fragmentShader = await createShader(gl, gl.FRAGMENT_SHADER, 'shader/dropshadow.frag');
    const program = createProgram(gl, fragmentShader, vertexShader);

    this.programInfo = {
      program,
      attribLocations: {
        vertexPosition: gl.getAttribLocation(program, 'aVertexPosition'),
      },
      uniformLocations: {
        modelViewProjectionMatrix: gl.getUniformLocation(program, 'uMVP'),
        aspectRatio: gl.getUniformLocation(program, 'uAspectRatio'),
      },
    };
  }

  render(renderConfig, imageConfig) {
    const { gl } = this;
    const { program, attribLocations, uniformLocations } = this.programInfo;
    gl.useProgram(program);

    const { viewProjectionMatrix } = createViewProjectionMatrix(gl, renderConfig);

    const modelMatrix = mat4.create();
    mat4.rotate(modelMatrix, modelMatrix, glMatrix.toRadian(renderConfig.rotation.x), [0.0, 1.0, 0.0]);
    mat4.rotate(modelMatrix, modelMatrix, glMatrix.toRadian(renderConfig.rotation.y), [1.0, 0.0, 0.0]);

    const MVP = mat4.create();
    mat4.multiply(MVP, viewProjectionMatrix, modelMatrix);

    // buffers
    const vertexBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, vertexBuffer);
    gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(squareXY.vertices), gl.STATIC_DRAW);
    gl.vertexAttribPointer(attribLocations.vertexPosition, 3, gl.FLOAT, false, 0, 0);
    gl.enableVertexAttribArray(attribLocations.vertexPosition);

    gl.uniform1f(uniformLocations.aspectRatio, imageConfig.frameAspectRatio);
    gl.uniformMatrix4fv(uniformLocations.modelViewProjectionMatrix, false, MVP);

    gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4);
  }
}

export default DropShadow;
