const initialState = {
  isAuthorized: false,
};

function instagram(state = initialState, { type, ...rest }) {
  switch (type) {
    case 'SET_INSTAGRAM_CONFIG':
      return {
        ...state,
        ...rest,
      };
    default:
      return state;
  }
}

export default instagram;
