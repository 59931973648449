import React from 'react';
import { IoIosArrowRoundForward } from 'react-icons/io';
import ButtonLink from '../../utility/buttons/ButtonLink';

function StartSectionButton() {
  return (
    <ButtonLink
      to="/editor"
      className="StartSection__button"
      size={3}
    >
      <span>
        <span className="show-sm">Deine{' '}</span>
        Gravur konfigurieren
      </span>
      <IoIosArrowRoundForward style={{ fontSize: 'var(--font-size-6)' }} />
    </ButtonLink>
  );
}

export default StartSectionButton;
