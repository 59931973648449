import React from 'react';
import './ErrorText.scss';

function ErrorText({ children }) {
  return (
    <span className="ErrorText">
      {children}
    </span>
  );
}

export default ErrorText;
