import { Component } from 'react';
import { connect } from 'react-redux';

class ImageZoomListener extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lastTouchDistance: null,
    };
  }

  componentDidMount() {
    const element = document.getElementById('GLCanvas');
    element.addEventListener('mousewheel', this.handleMouseWheel, false);
    element.addEventListener('touchstart', this.handleTouchStart, false);
    element.addEventListener('touchmove', this.handleTouchMove, false);
    element.addEventListener('touchend', this.handleTouchEnd, false);
  }

  componentWillUnmount() {
    const element = document.getElementById('GLCanvas');
    element.removeEventListener('mousewheel', this.handleMouseWheel, false);
    element.removeEventListener('touchstart', this.handleTouchStart, false);
    element.removeEventListener('touchmove', this.handleTouchMove, false);
    element.addEventListener('touchend', this.handleTouchEnd, false);
  }

  handleMouseWheel = ({ wheelDeltaY }) => {
    this.props.dispatch({
      type: 'ZOOM_IMAGE_BY',
      step: wheelDeltaY / 2000.0,
    });
  };

  handleTouchStart = (e) => {
    e.preventDefault();

    if (e.touches.length === 2) {
      const dist = Math.hypot(e.touches[0].pageX - e.touches[1].pageX, e.touches[0].pageY - e.touches[1].pageY);
      this.setState({ lastTouchDistance: dist });
    }
  };

  handleTouchMove = (e) => {
    const { lastTouchDistance } = this.state;

    if (lastTouchDistance) {
      e.preventDefault();
      const dist = Math.hypot(e.touches[0].pageX - e.touches[1].pageX, e.touches[0].pageY - e.touches[1].pageY);
      const diff = lastTouchDistance - dist;

      this.props.dispatch({
        type: 'ZOOM_IMAGE_BY',
        step: -diff / 200.0,
      });

      this.setState({ lastTouchDistance: dist });
    }
  };

  handleTouchEnd = () => {
    this.setState({ lastTouchDistance: null });
  };

  render() {
    return null;
  }
}

export default connect()(ImageZoomListener);
