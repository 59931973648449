import React, { Component } from 'react';
import { connect } from 'react-redux';
import Slider from 'rc-slider';
import {
  MAX_CONTRAST,
  MIN_CONTRAST,
  CONTRAST_STEP,
} from '../../state/reducers/images/config';
import 'rc-slider/assets/index.css';

class ContrastSlider extends Component {
  handleChange = (value) => {
    this.props.dispatch({
      type: 'SET_CONTRAST',
      value,
    });
  };

  render() {
    const { contrast } = this.props;

    return (
      <div>
        <Slider
          max={MAX_CONTRAST}
          min={MIN_CONTRAST}
          step={CONTRAST_STEP}
          value={contrast}
          onChange={this.handleChange}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  contrast: state.images.items[state.images.active].contrast,
});

export default connect(mapStateToProps)(ContrastSlider);
