import api from '../config/api';

class PayPalOrderService {
  static async create(requestData) {
    const response = await api.post('/paypal/orders', requestData);
    return response.data;
  }

  static async capture(orderId, requestData) {
    const response = await api.post(`/paypal/orders/${orderId}/capture`, requestData);
    return response.data;
  }
}

export default PayPalOrderService;
