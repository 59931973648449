import { useState, useEffect } from 'react';
import StripePaymentIntentService from '../../services/StripePaymentIntentService';

function usePaymentIntent(requestData, onError) {
  const [paymentIntent, setPaymentIntent] = useState(null);

  useEffect(() => {
    async function createOrUpdatePaymentIntent() {
      try {
        const result = !paymentIntent
          ? await StripePaymentIntentService.create(requestData)
          : await StripePaymentIntentService.update(paymentIntent.id, requestData);

        setPaymentIntent(result);
      } catch (error) {
        if (error.response) {
          onError(error);
        } else {
          onError(`
            Bei der Verbindung mit unserem Server ist ein Fehler aufgetreten.
            Zahlungen mit Kreditkarte können davon beeinträchtigt sein.
          `);
        }
      }
    }

    createOrUpdatePaymentIntent();
  }, [requestData.items.length, requestData.discountCode]);

  return paymentIntent;
}

export default usePaymentIntent;
