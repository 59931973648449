import React from 'react';
import classNames from 'classnames';

function OverlayControlButton({
  title,
  Icon,
  onClick,
  isActive,
  ...rest
}) {
  const className = classNames('OverlayControls__button', {
    'OverlayControls__button--active': isActive,
  });

  return (
    <button
      className={className}
      onClick={onClick}
      type="button"
      {...rest}
    >
      <Icon />
      <span>{title}</span>
    </button>
  );
}

export default OverlayControlButton;
