import React, { useState, useEffect } from 'react';
import className from 'classnames';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import gravure0 from '../../../assets/images/gravure0.jpg';
import gravure1 from '../../../assets/images/gravure1.jpg';
import gravure2 from '../../../assets/images/gravure2.jpg';
import gravure3 from '../../../assets/images/gravure3.jpg';
import gravure4 from '../../../assets/images/gravure4.jpg';
import gravure5 from '../../../assets/images/gravure5.jpg';
import gravure6 from '../../../assets/images/gravure6.jpg';
import gravure7 from '../../../assets/images/gravure7.jpg';
import './StartSectionSlider.scss';

const images = [gravure0, gravure1, gravure2, gravure3, gravure4, gravure5, gravure6, gravure7];

function StartSectionSlider() {
  const [currentIndex, setCurrentIndex] = useState(0);

  function prev() {
    const { length } = images;
    const prevIndex = (((currentIndex - 1) % length) + length) % length;
    setCurrentIndex(prevIndex);
  }

  function next() {
    const nextIndex = (currentIndex + 1) % images.length;
    setCurrentIndex(nextIndex);
  }

  useEffect(() => {
    const timeout = setTimeout(next, 10000);
    return () => clearTimeout(timeout);
  }, [currentIndex]);

  return (
    <div className="Slider">
      <button
        className="Slider__arrow Slider__arrow--left"
        onClick={prev}
      >
        <IoIosArrowBack />
      </button>
      {images.map((src, index) => (
        <img
          key={src}
          className={className('Slider__image', {
            'Slider__image--active': currentIndex === index,
          })}
          src={src}
          alt="Gravur Vorschau 1"
        />
      ))}
      <button
        className="Slider__arrow Slider__arrow--right"
        onClick={next}
      >
        <IoIosArrowForward />
      </button>
    </div>
  );
}

export default StartSectionSlider;
