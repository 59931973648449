import React from 'react';
import Confirmation from './Confirmation';

function PayPalConfirmation(props) {
  const { name, email } = props.location.state.customer;

  return (
    <Confirmation
      name={name}
      email={email}
    />
  );
}

export default PayPalConfirmation;
