import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Button from './Button';
import './Button.scss';

function ButtonLink({
  to,
  children,
  className,
  disabled,
  size,
  ...rest
}) {
  if (disabled) {
    return (
      <Button disabled className={className} size={size} {...rest}>
        {children}
      </Button>
    );
  }

  const styleClasses = classNames('Button', `-size-${size}`, className, {
    '-disabled': disabled,
    '-white': rest.white,
    '-plain': rest.plain,
  });

  return (
    <Link
      {...rest}
      to={to}
      className={styleClasses}
    >
      {children}
    </Link>
  );
}

ButtonLink.defaultProps = {
  className: '',
  disabled: false,
  size: 1,
};

export default ButtonLink;
