import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import configureStore from '../state/configureStore';
import App from './App';
import Home from './home/Home';

const { store, persistor } = configureStore();

function Root() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/" component={App} />
          </Switch>
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default Root;
