import { useState, useEffect } from 'react';
import { useWindowSize } from 'react-use';
import { connect } from 'react-redux';

const SPEED = 10.0;

function useMouse() {
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);

  function handleMouseMove(e) {
    setX(e.clientX);
    setY(e.clientY);
  }

  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove);

    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, []);

  return { x, y };
}

function MouseMovementListener(props) {
  const { x, y } = useMouse();
  const { width, height } = useWindowSize();

  function rotate() {
    props.dispatch({
      type: 'ROTATE_TO',
      x: ((x / width) * 2.0 - 1.0) * SPEED,
      y: ((y / height) * 2.0 - 1.0) * SPEED,
    });
  }

  requestAnimationFrame(rotate);

  return null;
}

export default connect()(MouseMovementListener);
