import React, { useState } from 'react';
import queryString from 'query-string';
import { Redirect } from 'react-router-dom';
import ActivityIndicator from '../utility/ActivityIndicator';
import Confirmation from './Confirmation';
import useStripeJs from '../../hooks/useStripeJs';
import './Confirmation.scss';

function StripePaymentIntentConfirmation(props) {
  const [paymentIntent, setPaymentIntent] = useState(null);
  const [error, setError] = useState(null);

  useStripeJs(async (stripe) => {
    const { location } = props;
    const parsed = queryString.parse(location.search);

    const result = await stripe.retrievePaymentIntent(parsed.client_secret);
    setPaymentIntent(result.paymentIntent);
    setError(result.error);
  });

  if (!paymentIntent && !error) {
    return (
      <div className="Confirmation__loading">
        <ActivityIndicator size={2} />
        <span>Wird geprüft...</span>
      </div>
    );
  }

  if (error) {
    return (
      <Redirect
        to={{
          pathname: '/checkout',
          state: {
            error: `
              Bei dem Bezahl-Vorgang ist ein Fehler aufgetreten.
              Bitte versuchen Sie es erneut.
            `,
          },
        }}
      />
    );
  }

  return (
    <Confirmation
      name={paymentIntent.shipping.name}
      email={paymentIntent.receipt_email}
    />
  );
}

export default StripePaymentIntentConfirmation;
