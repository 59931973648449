import { applyMiddleware, createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import rootReducer from './rootReducer';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [
    'discount',
    'controlMode',
    'rotation',
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

function configureStore() {
  let middleware = [thunk];

  if (process.env.NODE_ENV === 'development' && !process.env.REACT_APP_DISABLE_REDUX_LOGGER) {
    const { logger } = require('redux-logger');
    middleware = [...middleware, logger];
  }

  const store = createStore(persistedReducer, applyMiddleware(...middleware));
  const persistor = persistStore(store);

  return { store, persistor };
}

export default configureStore;
