import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Form from 'react-formal';
import { injectStripe, CardElement } from 'react-stripe-elements';
import { IoIosArrowRoundBack } from 'react-icons/io';
import ButtonLink from '../utility/buttons/ButtonLink';
import AddressSection from './sections/AddressSection';
import PaymentMethodSelector from './sections/PaymentMethodSelector';
import StripeCheckout from '../../services/StripeCheckout';
import Sidebar from '../utility/Sidebar';
import CostSection from './sections/CostSection';
import ErrorText from '../utility/ErrorText';
import SubmitSection from './sections/SubmitSection';
import formSchema from './formSchema';
import usePaymentIntent from './usePaymentIntent';
import getProcessedImage from '../canvas/getProcessedImage';
import UploadService from '../../services/UploadService';
import OrderService from '../../services/OrderService';
import transformToOrderItems from './transformToOrderItem';

function CheckoutSidebarForm(props) {
  const {
    stripe,
    dispatch,
    location,
    formData,
    discount,
    imageConfigs,
    orderItems,
  } = props;

  const [checkoutError, setCheckoutError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const paymentIntent = usePaymentIntent({
    items: orderItems,
    discountCode: discount ? discount.code : '',
  }, setCheckoutError);

  useEffect(() => {
    if (location.state && location.state.error) {
      setCheckoutError(location.state.error);
    }
  }, [location.state]);

  function setFormData(data) {
    dispatch({ type: 'SET_CHECKOUT_FORM_DATA', ...data });
  }

  function handleInputChange(e) {
    const { target: { name, value } } = e;
    setFormData({ [name]: value });
  }

  function handlePaymentMethodChange(e) {
    setFormData({ paymentMethod: e.target.value });
  }

  function getItemsData() {
    return Promise.all(imageConfigs.map(async (imageConfig) => {
      const images = {
        original: imageConfig.src,
        processed: await getProcessedImage(imageConfig),
      };

      const urls = await UploadService.uploadProcessImages(images);

      return {
        originalImageUrl: urls.urlOriginal,
        processedImageUrl: urls.urlProcessed,
        width: imageConfig.frameWidth,
        height: imageConfig.frameHeight,
      };
    }));
  }

  async function createRequestData() {
    return {
      items: await getItemsData(),
      ...(discount && { discountCode: discount.code }),
    };
  }

  async function handleStripeSubmit() {
    setIsSubmitting(true);

    const order = await OrderService.create({
      customer: {
        name: formData.name,
        email: formData.email,
        city: formData.city,
        country: 'Deutschland',
        addressLine: formData.address,
        postalCode: formData.postalCode,
      },
      items: await getItemsData(),
      discountCode: discount.code,
    });

    const checkout = new StripeCheckout(
      stripe,
      paymentIntent,
      order,
      discount,
    );

    try {
      await checkout[formData.paymentMethod]();
    } catch (error) {
      setIsSubmitting(false);
      setCheckoutError(error.message);
    }
  }

  function handleCheckoutError(message) {
    setCheckoutError(message);
  }

  const shouldCollectAddress = ['card', 'giropay', 'sofort'].includes(formData.paymentMethod);
  const shouldShowRedirectInfo = ['giropay', 'sofort'].includes(formData.paymentMethod);
  const shouldShowRedirectDeliveryInfo = ['sepa', 'paypal'].includes(formData.paymentMethod);

  return (
    <Form
      onSubmit={handleStripeSubmit}
      className="Sidebar__form"
      schema={formSchema}
      value={formData}
      onChange={setFormData}
    >
      <Sidebar.Section>
        <ButtonLink
          to="/editor"
          size={2}
          className="CheckoutSidebar__back-button"
        >
          <IoIosArrowRoundBack style={{ fontSize: 'var(--font-size-7)' }} />
          <span>Zurück zum Editieren</span>
        </ButtonLink>
      </Sidebar.Section>
      <Sidebar.Section>
        <h3 className="Sidebar__section__title">Bezahlung</h3>
        <PaymentMethodSelector
          activeMethod={formData.paymentMethod}
          onChange={handlePaymentMethodChange}
        />
        {formData.paymentMethod === 'card' && (
          <CardElement style={{ base: { fontSize: '16px' } }} />
        )}
        {shouldShowRedirectInfo && (
          <p className="Sidebar__info">
            Beim Bestätigen des Formulars leiten wir Sie zum ausgewählten Dienst weiter.
          </p>
        )}
        {shouldShowRedirectDeliveryInfo && (
          <p className="Sidebar__info">
            Beim Bestätigen des Formulars leiten wir Sie zum ausgewählten Dienst weiter,
            wo sie auch die Angaben zur Lieferung tätigen können.
          </p>
        )}
      </Sidebar.Section>
      {shouldCollectAddress && (
        <Sidebar.Section>
          <h3 className="Sidebar__section__title">Lieferung</h3>
          <AddressSection
            formData={formData}
            onChange={handleInputChange}
          />
        </Sidebar.Section>
      )}
      <CostSection />
      <Sidebar.Section>
        <span className="CheckoutSidebar__agb-consent">
          Durch das Absenden der Bestellung erkläre ich mich mit den
          {' '}
          <a href="/static/agb.html">Allgemeinen Geschäftsbedinungen</a>
          {' '}
          von gravier-dein-bild.de einverstanden.
        </span>
      </Sidebar.Section>
      {checkoutError && (
        <Sidebar.Section>
          <ErrorText>
            {typeof checkoutError === 'object'
              ? JSON.stringify(checkoutError)
              : checkoutError}
          </ErrorText>
        </Sidebar.Section>
      )}
      <SubmitSection
        createRequestData={createRequestData}
        paymentMethod={formData.paymentMethod}
        setIsSubmitting={setIsSubmitting}
        isSubmitting={isSubmitting}
        onSubmitError={handleCheckoutError}
      />
    </Form>
  );
}

const mapStateToProps = state => ({
  discount: state.discount,
  formData: state.checkoutForm,
  imageConfigs: state.images.items,
  orderItems: transformToOrderItems(state.images.items),
});

export default connect(mapStateToProps)(injectStripe(withRouter(CheckoutSidebarForm)));
