import { useEffect, useState } from 'react';

function useStripeJs(onLoad = () => {}) {
  const [stripe, setStripe] = useState(null);

  useEffect(() => {
    function handleStripeLoad() {
      const stripeInstance = window.Stripe(process.env.REACT_APP_STRIPE_API_KEY);
      setStripe(stripeInstance);
      onLoad(stripeInstance);
    }

    if (window.Stripe) {
      handleStripeLoad();
    } else {
      document.querySelector('#stripe-js').addEventListener('load', handleStripeLoad);
    }
  }, []);

  return stripe;
}

export default useStripeJs;
