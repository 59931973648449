const MAX_ROTATION = 12;

const initialState = {
  x: 0,
  y: 0,
};

function rotation(state = initialState, action) {
  switch (action.type) {
    case 'ROTATE_BY':
      return {
        ...state,
        x: (action.stepX > 0)
          ? Math.min(MAX_ROTATION, state.x + action.stepX)
          : Math.max(-MAX_ROTATION, state.x + action.stepX),
        y: (action.stepY > 0)
          ? Math.min(MAX_ROTATION, state.y + action.stepY)
          : Math.max(-MAX_ROTATION, state.y + action.stepY),
      };
    case 'ROTATE_TO':
      return {
        ...state,
        x: (action.x > 0)
          ? Math.min(MAX_ROTATION, action.x)
          : Math.max(-MAX_ROTATION, action.x),
        y: (action.y > 0)
          ? Math.min(MAX_ROTATION, action.y)
          : Math.max(-MAX_ROTATION, action.y),
      };
    default:
      return state;
  }
}

export default rotation;
