import queryString from 'query-string';
import StripePaymentIntentService from './StripePaymentIntentService';
import PriceCalculator from './PriceCalculator';

class StripeCheckout {
  constructor(stripe, paymentIntent, order, discount) {
    this.stripe = stripe;
    this.order = order;
    this.customer = order.customer;
    this.metadata = { order_id: order.id };
    this.paymentIntent = paymentIntent;

    this.amount = (new PriceCalculator())
      .setItems(order.items)
      .setDiscount(discount.discount)
      .getTotal() * 100;
  }

  getAddress() {
    return {
      city: this.customer.city,
      country: this.customer.country,
      line1: this.customer.addressLine,
      postal_code: this.customer.postalCode,
    };
  }

  getSourceData(type, additionalData) {
    const confirmationUrl = `${window.location.origin}/confirmation/s/source`;

    return {
      type,
      amount: this.amount,
      currency: 'eur',
      owner: {
        name: this.customer.name,
        email: this.customer.email,
        address: this.getAddress(),
      },
      metadata: this.metadata,
      redirect: {
        return_url: confirmationUrl,
      },
      ...additionalData,
    };
  }

  async card() {
    const { clientSecret } = await StripePaymentIntentService.updateMetadata(this.paymentIntent.id, this.metadata);

    const { paymentIntent, error } = await this.stripe.handleCardPayment(
      clientSecret,
      {
        receipt_email: this.customer.email,
        shipping: {
          name: this.customer.name,
          address: this.getAddress(),
        },
      },
    );

    if (error) throw error;

    const confirmationUrl = `${window.location.origin}/confirmation/s/payment_intent`;

    window.location = `${confirmationUrl}?${queryString.stringify({
      client_secret: paymentIntent.client_secret,
    })}`;
  }

  async giropay() {
    // https://stripe.com/docs/sources/giropay

    const sourceData = this.getSourceData('giropay');

    const { source, error } = await this.stripe.createSource(sourceData);

    if (error) throw error;

    window.location = source.redirect.url;
  }

  async sofort() {
    // https://stripe.com/docs/sources/sofort

    const sourceData = this.getSourceData('sofort', {
      sofort: {
        country: 'DE',
      },
    });

    const { source, error } = await this.stripe.createSource(sourceData);

    if (error) throw error;

    window.location = source.redirect.url;
  }
}

export default StripeCheckout;
