import React from 'react';
import formatPreview103x103 from '../../assets/images/format_previews/format_preview_103x103.jpg';
import formatPreview145x103 from '../../assets/images/format_previews/format_preview_145x103.jpg';
import FormatSelectorItem from './FormatSelectorItem';
import './FormatSelector.scss';

const formats = [
  {
    image: formatPreview145x103,
    width: 14.5,
    height: 10.3,
  },
  {
    image: formatPreview103x103,
    width: 10.3,
    height: 10.3,
  },
];

function FormatSelector() {
  return (
    <div className="FormatSelector">
      {formats.map(format => (
        <FormatSelectorItem
          format={format}
        />
      ))}
    </div>
  );
}

export default FormatSelector;
