import { mat4, vec3, glMatrix } from 'gl-matrix';

function createViewProjectionMatrix(gl, config) {
  const zoom = (config.zoom / 100.0 - 1.0) * 2.5;

  const eye = vec3.fromValues(0.0, 0.0, 5.0 - zoom);
  const up = vec3.fromValues(0.0, 1.0, 0.0);
  const center = vec3.fromValues(0.0, 0.0, 0.0);
  const light = vec3.fromValues(0.0, 3.0, 1.5);

  const viewMatrix = mat4.create();
  mat4.lookAt(viewMatrix, eye, center, up);

  const aspect = gl.canvas.clientWidth / gl.canvas.clientHeight;
  const projectionMatrix = mat4.create();
  mat4.perspective(projectionMatrix, glMatrix.toRadian(45), aspect, 1, 100);

  const viewProjectionMatrix = mat4.create();
  mat4.multiply(viewProjectionMatrix, projectionMatrix, viewMatrix);

  return {
    eye,
    light,
    viewMatrix,
    projectionMatrix,
    viewProjectionMatrix,
  };
}

export default createViewProjectionMatrix;
