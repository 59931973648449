// @flow
import humps from 'humps';

export default (instance) => {
  instance.interceptors.request.use(
    (config) => {
      if (config.params) {
        return {
          ...config,
          params: humps.decamelizeKeys(config.params),
        };
      }

      if (config.data && !(config.data instanceof FormData)) {
        return {
          ...config,
          data: humps.decamelizeKeys(config.data),
        };
      }

      return config;
    },
  );

  instance.interceptors.response.use(
    response => ({
      ...response,
      data: humps.camelizeKeys((response.data: any)),
    }),
  );
};
