// @flow
import React from 'react';
import classNames from 'classnames';
import Button from './Button';
import './PurchaseButton.scss';

function PurchaseButton({ className, children, ...rest }) {
  return (
    <Button
      className={classNames('PurchaseButton', className)}
      {...rest}
    >
      {children}
    </Button>
  );
}

export default PurchaseButton;
