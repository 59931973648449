import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { useMedia } from 'react-use';
import ZoomListener from './ZoomListener';
import ImageDisplacementListener from './ImageDisplacementListener';
import ImageZoomListener from './ImageZoomListener';
import { controlModes } from '../../state/reducers/editor/controlMode';
import ControlModeRotationHandler from './ControlModeRotationHandler';
import DeviceMotionListener from './DeviceMotionListener';
import MouseMovementListener from './MouseMovementListener';

function HandlerManager(props) {
  const { controlMode } = props;
  const isDesktop = useMedia('(min-width: 920px)');

  return (
    <Fragment>
      <ControlModeRotationHandler />
      {controlMode === controlModes.CAMERA && (
        <Fragment>
          {isDesktop ? (
            <MouseMovementListener />
          ) : (
            <DeviceMotionListener />
          )}
          <ZoomListener />
        </Fragment>
      )}
      {controlMode === controlModes.CROP && (
        <Fragment>
          <ImageDisplacementListener />
          <ImageZoomListener />
        </Fragment>
      )}
    </Fragment>
  );
}

const mapStateToProps = state => ({
  controlMode: state.controlMode,
});

export default connect(mapStateToProps)(HandlerManager);
