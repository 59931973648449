import React from 'react';
import { StripeProvider, Elements } from 'react-stripe-elements';
import CheckoutSidebarForm from './CheckoutSidebarForm';
import Sidebar from '../utility/Sidebar';
import useStripeJs from '../../hooks/useStripeJs';
import './CheckoutSidebar.scss';

function CheckoutSidebar() {
  const stripe = useStripeJs();

  return (
    <Sidebar className="CheckoutSidebar">
      {stripe && (
        <StripeProvider stripe={stripe}>
          <Elements>
            <CheckoutSidebarForm />
          </Elements>
        </StripeProvider>
      )}
    </Sidebar>
  );
}

export default CheckoutSidebar;
