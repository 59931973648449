// @flow
import React from 'react';
import ReactModal from 'react-modal';
import classNames from 'classnames';
import { IoIosClose } from 'react-icons/io';
import Button from './buttons/Button';
import './Modal.scss';

ReactModal.setAppElement('#root');

function Modal({
  overlayClassName,
  className,
  children,
  onRequestClose,
  ...rest
}) {
  return (
    <ReactModal
      overlayClassName={classNames('Modal__overlay', overlayClassName)}
      className={classNames('Modal__content', className)}
      {...rest}
    >
      <Button plain className="Modal__close-button" onClick={onRequestClose}>
        <IoIosClose />
      </Button>
      {children}
    </ReactModal>
  );
}

Modal.defaultProps = {
  overlayClassName: '',
  className: '',
  onRequestClose: () => {},
};

export default Modal;
