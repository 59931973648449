function loadImage(imageSrc) {
  const promise = new Promise((resolve) => {
    const image = new Image();
    image.onload = () => resolve(image);
    image.src = imageSrc;
  });

  return promise;
}

export default loadImage;
