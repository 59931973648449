/* eslint-disable indent, no-multi-spaces */

export const HEIGHT = 0.04;
const MAX_Y = HEIGHT / 2.0;

const geometry = {
  vertices: [
    // Front face
    -1.0, -MAX_Y,  1.0,
     1.0, -MAX_Y,  1.0,
     1.0,  MAX_Y,  1.0,
    -1.0,  MAX_Y,  1.0,

    // Back face
    -1.0, -MAX_Y, -1.0,
    -1.0,  MAX_Y, -1.0,
     1.0,  MAX_Y, -1.0,
     1.0, -MAX_Y, -1.0,

    // Bottom face
    -1.0, -MAX_Y, -1.0,
     1.0, -MAX_Y, -1.0,
     1.0, -MAX_Y,  1.0,
    -1.0, -MAX_Y,  1.0,

    // Right face
     1.0, -MAX_Y, -1.0,
     1.0,  MAX_Y, -1.0,
     1.0,  MAX_Y,  1.0,
     1.0, -MAX_Y,  1.0,

    // Left face
    -1.0, -MAX_Y, -1.0,
    -1.0, -MAX_Y,  1.0,
    -1.0,  MAX_Y,  1.0,
    -1.0,  MAX_Y, -1.0,
  ],
  indices: [
    0,  1,  2,      0,  2,  3,    // front
    4,  5,  6,      4,  6,  7,    // back
    8,  9,  10,     8,  10, 11,   // bottom
    12, 13, 14,     12, 14, 15,   // right
    16, 17, 18,     16, 18, 19,   // left
  ],
  textureCoordinates: [
    // Front
    0.0,     0.0,
    0.0,     1.0,
    MAX_Y,  1.0,
    MAX_Y,  0.0,
    // Back
    0.0,     0.0,
    MAX_Y,  0.0,
    MAX_Y,  1.0,
    0.0,     1.0,
    // Bottom
    0.0,  0.0,
    1.0,  0.0,
    1.0,  1.0,
    0.0,  1.0,
    // Right
    0.0,  0.0,
    0.0,  MAX_Y,
    1.0,  MAX_Y,
    1.0,  0.0,
    // Left
    0.0,  0.0,
    1.0,  0.0,
    1.0,  MAX_Y,
    0.0,  MAX_Y,
  ],
};

export default geometry;
