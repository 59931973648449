import {
  minZoom,
  DEFAULT_ASPECT_RATIO,
  innerAspectRatio,
  DEFAULT_HEIGHT,
  DEFAULT_WIDTH,
} from './reducers/images/config';

const width = 1000;
const height = 688;

const defaultImage = {
  src: 'resources/images/default_image.1000.688.jpg',
  width,
  height,
  aspectRatio: width / height,
  // frame
  frameWidth: DEFAULT_WIDTH,
  frameHeight: DEFAULT_HEIGHT,
  frameAspectRatio: DEFAULT_ASPECT_RATIO,
  innerAspectRatio: innerAspectRatio(DEFAULT_WIDTH, DEFAULT_HEIGHT),
  // processing
  brightness: 1.2,
  contrast: 0.0,
  // displacement
  position: { x: 0, y: 0 },
  zoom: minZoom(DEFAULT_ASPECT_RATIO, width / height),
};

export default defaultImage;
