import { Component } from 'react';
import { connect } from 'react-redux';

class ImageDisplacementListener extends Component {
  componentDidMount() {
    const element = document.getElementById('GLCanvas');

    element.addEventListener('mousedown', this.handleMouseStart, false);
    element.addEventListener('mousemove', this.handleMouseMove, false);
    element.addEventListener('mouseup', this.handleDragStop, false);

    element.addEventListener('touchstart', this.handleTouchStart, false);
    element.addEventListener('touchmove', this.handleTouchMove, false);
    element.addEventListener('touchend', this.handleDragStop, false);
  }

  componentWillUnmount() {
    const element = document.getElementById('GLCanvas');

    element.removeEventListener('mousedown', this.handleMouseStart);
    element.removeEventListener('mousemove', this.handleMouseMove);
    element.removeEventListener('mouseup', this.handleDragStop);

    element.removeEventListener('touchstart', this.handleTouchStart);
    element.removeEventListener('touchmove', this.handleTouchMove);
    element.removeEventListener('touchup', this.handleDragStop);
  }

  handleTouchStart = (e) => {
    if (e.touches.length > 1) return;

    this.lastDrag = {
      x: e.touches[0].clientX,
      y: e.touches[0].clientY,
    };
  };

  handleTouchMove = (e) => {
    if (!this.lastDrag || e.touches.length > 1) return;
    this.drag(e.touches[0].clientX, e.touches[0].clientY, 0.002);
  };

  handleMouseStart = (e) => {
    this.lastDrag = {
      x: e.clientX,
      y: e.clientY,
    };
  };

  handleMouseMove = (e) => {
    if (!this.lastDrag) return;
    this.drag(e.clientX, e.clientY, 0.001);
  };


  handleDragStop = () => {
    this.lastDrag = null;
  };

  drag(x, y, speed) {
    const { dispatch } = this.props;

    dispatch({
      type: 'MOVE_IMAGE_BY',
      dx: (x - this.lastDrag.x) * speed,
      dy: (y - this.lastDrag.y) * speed,
    });

    this.lastDrag = { x, y };
  }

  render() {
    return null;
  }
}

export default connect()(ImageDisplacementListener);
