const initialState = {
  paymentMethod: 'paypal',
  name: '',
  email: '',
  address: '',
  city: '',
  postalCode: '',
  discountCode: '',
};

function checkoutForm(state = initialState, action) {
  switch (action.type) {
    case 'SET_CHECKOUT_FORM_DATA': {
      const { type, ...formData } = action;

      return {
        ...state,
        ...formData,
      };
    }
    default:
      return state;
  }
}

export default checkoutForm;
