const initialState = null;

function discount(state = initialState, action) {
  switch (action.type) {
    case 'SET_DISCOUNT':
      return {
        code: action.code,
        discount: action.discount,
      };
    case 'REMOVE_DISCOUNT':
      return null;
    default:
      return state;
  }
}

export default discount;
