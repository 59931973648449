import React, { useRef } from 'react';
import { useMedia } from 'react-use';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import classNames from 'classnames';
import { IoIosArrowRoundBack } from 'react-icons/io';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import CheckoutSidebar from '../checkout_sidebar/CheckoutSidebar';
import EditorControls from '../controls/EditorControls';
import ImportControls from '../controls/import/ImportControls';
import HandlerManager from './HandlerManager';
import ButtonLink from '../utility/buttons/ButtonLink';
import Footer from '../footer/Footer';
import GLCanvas from './GLCanvas';
import './GLCanvas.scss';
import './Editor.scss';

function Editor(props) {
  const isDesktop = useMedia('(min-width: 920px)', true);
  const glCanvasRef = useRef(null);
  const isCheckoutOpen = props.location.pathname === '/checkout';

  const className = classNames('Editor', {
    'Editor--sidebar-open': isCheckoutOpen,
  });

  return (
    <div className={className}>
      <HandlerManager />
      <TransitionGroup>
        <CSSTransition
          key={props.location.key}
          classNames="fade"
          timeout={{
            enter: 700,
            exit: 200,
          }}
        >
          <Switch location={props.location}>
            <Route
              path="/editor"
              render={({ match, ...rest }) => (
                <>
                  <ButtonLink
                    to="/"
                    className="Editor__back-button"
                    title="Vorschau verlassen"
                    plain
                  >
                    <IoIosArrowRoundBack style={{ fontSize: 'var(--font-size-7)' }} />
                  </ButtonLink>
                  <EditorControls {...rest} />
                  <ImportControls {...rest} />
                </>
              )}
            />
          </Switch>
        </CSSTransition>
      </TransitionGroup>
      <GLCanvas
        ref={glCanvasRef}
        isBesideCheckout={isCheckoutOpen}
      />
      <TransitionGroup>
        <CSSTransition
          key={props.location.key}
          classNames="slide"
          timeout={{
            enter: isDesktop ? 700 : 200,
            exit: 200,
          }}
        >
          <Switch location={props.location}>
            <Route
              path="/checkout"
              render={routerProps => (
                <div className="Editor__sidebar-container">
                  <CheckoutSidebar {...routerProps} />
                </div>
              )}
            />
          </Switch>
        </CSSTransition>
      </TransitionGroup>
      <Footer />
    </div>
  );
}

const mapStateToProps = state => ({
  images: state.images.items,
});

export default connect(mapStateToProps)(Editor);
