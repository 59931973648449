import React, { useState } from 'react';
import SmartPaymentButtons from 'react-smart-payment-buttons';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PayPalOrderService from '../../../services/PayPalOrderService';
import ActivityIndicator from '../../utility/ActivityIndicator';
import transformToOrderItems from '../transformToOrderItem';

function PayPalButtons(props) {
  const { discount, items, setIsSubmitting } = props;
  const [isApproved, setIsApproved] = useState(false);
  const [customer, setCustomer] = useState(null);

  async function createOrder() {
    setIsSubmitting(true);

    const { orderID } = await PayPalOrderService.create({
      items,
      ...(discount && { discountCode: discount.code }),
    });

    return orderID;
  }

  async function onApprove(data) {
    const requestData = await props.createRequestData();
    const responseData = await PayPalOrderService.capture(data.orderID, requestData);

    setCustomer(responseData);
    setIsApproved(true);
    setIsSubmitting(false);
  }

  function onCancel() {
    setIsSubmitting(false);

    props.onSubmitError(`
      Sie haben die Bezahlung abgebrochen.
      Aber keine Sorge, Sie können es jederzeit erneut versuchen.
    `);
  }

  function onError() {
    setIsSubmitting(false);

    props.onSubmitError(`
      Bei dem Bezahl-Vorgang ist ein Fehler aufgetreten.
      Bitte versuchen Sie es erneut.
    `);
  }

  if (isApproved) {
    return (
      <Redirect
        to={{
          pathname: '/confirmation/p',
          state: { customer },
        }}
      />
    );
  }

  return (
    <SmartPaymentButtons
      sdkScriptId="paypal-sdk"
      loading={<ActivityIndicator />}
      style={{
        color: 'blue',
        height: 45,
      }}
      containerStyle={{ width: '100%' }}
      refresh={props.discount}
      createOrder={createOrder}
      onApprove={onApprove}
      onCancel={onCancel}
      onError={onError}
    />
  );
}

const mapStateToProps = state => ({
  items: transformToOrderItems(state.images.items),
  discount: state.discount,
});

export default connect(mapStateToProps)(PayPalButtons);
