function createProgram(gl, ...shaders) {
  const program = gl.createProgram();
  shaders.forEach((shader) => {
    gl.attachShader(program, shader);
  });
  gl.linkProgram(program);

  if (!gl.getProgramParameter(program, gl.LINK_STATUS)) {
    const info = gl.getProgramParameter(program);
    throw new Error(`Initialization of shader program failed: ${info}`);
  }

  return program;
}

export default createProgram;
