import React from 'react';
import { IoIosCheckmarkCircleOutline } from 'react-icons/io';
import ButtonLink from '../utility/buttons/ButtonLink';
import './Confirmation.scss';

function Confirmation(props) {
  const { name, email } = props;

  return (
    <div className="Confirmation">
      <header className="Confirmation__header">
        <IoIosCheckmarkCircleOutline className="Confirmation__header__icon -success" />
        <div className="Confirmation__titles">
          <h1 className="Confirmation__title">
            Vielen Dank, {name}!
          </h1>
          <h2 className="Confirmation__subtitle">
            Ihre Bestellung wurde erfolgreich aufgenommen.
          </h2>
        </div>
      </header>
      <div className="Confirmation__info">
        <p>
          Wir haben Ihnen eine Bestellbestätigung per E-Mail zugesandt (an <i>{email}</i>).
        </p>
        <br />
        <p>
          Nachdem Ihre Zahlung eingegangen ist,
          erhalten Sie Ihre Gravur innerhalb von 2-3 Werktagen.
        </p>
      </div>
      <footer className="Confirmation__footer">
        <span>
          Einen schönen Tag wünscht<br />
          <b>Ihr Gravier-dein-Bild Team</b>
        </span>
        <ButtonLink
          to="/"
          className="Confirmation__footer__button"
          size={3}
        >
          <span>Zurück zur Startseite</span>
        </ButtonLink>
      </footer>
    </div>
  );
}

export default Confirmation;
