import React, { Fragment } from 'react';
import Sidebar from '../../utility/Sidebar';
import PayPalButtons from './PayPalButtons';
import ActivityIndicator from '../../utility/ActivityIndicator';
import PurchaseButton from '../../utility/buttons/PurchaseButton';

function SubmitSection(props) {
  const { paymentMethod, setIsSubmitting, isSubmitting } = props;

  return (
    <Sidebar.Section className="CheckoutSidebar__submit-section">
      {(props.paymentMethod === 'paypal') ? (
        <Fragment>
          {isSubmitting && (
            <div className="CheckoutSidebar__processing-info">
              <ActivityIndicator className="CheckoutSidebar__processing-info__spinner" />
              <span>
                Deine Bestellung wird verarbeitet.
                <br />
                Bitte habe einen Moment Geduld.
              </span>
            </div>
          )}
          <PayPalButtons
            createRequestData={props.createRequestData}
            setIsSubmitting={setIsSubmitting}
            onSubmitError={props.onSubmitError}
          />
        </Fragment>
      ) : (
        <Fragment>
          {isSubmitting ? (
            <ActivityIndicator />
          ) : (
            <Fragment>
              <PurchaseButton type="submit" size={2}>
                <span>Kostenpflichtig bestellen</span>
              </PurchaseButton>
              <span className="CheckoutSidebar__payment-method">
                {paymentMethod === 'card' && 'mit Kreditkarte'}
                {paymentMethod === 'giropay' && 'über GiroPay'}
                {paymentMethod === 'sofort' && 'über SOFORT'}
              </span>
            </Fragment>
          )}
        </Fragment>
      )}
    </Sidebar.Section>
  );
}

export default SubmitSection;
