export const controlModes = {
  CAMERA: 'CAMERA',
  CROP: 'CROP',
};

const initialState = controlModes.CAMERA;

function controlMode(state = initialState, action) {
  switch (action.type) {
    case 'SET_CONTROL_MODE':
      return action.mode;
    default:
      return state;
  }
}

export default controlMode;
