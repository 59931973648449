import React from 'react';
import './Footer.scss';

function Footer() {
  return (
    <footer className="Footer">
      <span className="Footer__copyright">
        Gravier dein Bild &copy; 2019
      </span>
      <ul className="Footer__link-list">
        <li className="Footer__link-list__item">
          <a href="/static/imprint.html">
            Impressum
          </a>
        </li>
        <li className="Footer__link-list__item">
          <a href="/static/privacy.html">
            Datenschutzerklärung
          </a>
        </li>
        <li className="Footer__link-list__item">
          <a href="/static/agb.html">
            AGB
          </a>
        </li>
      </ul>
    </footer>
  );
}

export default Footer;
