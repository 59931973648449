import api from '../config/api';

class StripePaymentIntentService {
  static async create(data) {
    const response = await api.post('/stripe/payment_intents', data);
    return response.data;
  }

  static async update(clientSecret, data) {
    const response = await api.put(`/stripe/payment_intents/${clientSecret}`, data);
    return response.data;
  }

  static async updateMetadata(clientSecret, metadata) {
    const response = await api.put(`/stripe/payment_intents/${clientSecret}/metadata`, metadata);
    return response.data;
  }
}

export default StripePaymentIntentService;
