import React from 'react';
import Form from 'react-formal';
import '../../utility/Form.scss';

function AddressSection(props) {
  const { onChange, formData } = props;

  return (
    <fieldset className="Form">
      <label htmlFor="name">
        <div>
          <span>Name</span>
          <Form.Field
            name="name"
            id="name"
            placeholder="Max Mustermann"
            value={formData.name}
            onChange={onChange}
            required
          />
        </div>
        <Form.Message
          for="name"
          className="Form__error"
        />
      </label>
      <label htmlFor="email">
        <div>
          <span>E-Mail</span>
          <Form.Field
            id="email"
            name="email"
            placeholder="max@example.com"
            value={formData.email}
            onChange={onChange}
            required
          />
        </div>
        <Form.Message
          for="email"
          className="Form__error"
        />
      </label>
      <label htmlFor="address">
        <div>
          <span>Adresse</span>
          <Form.Field
            id="address"
            name="address"
            placeholder="Breitenstraße 16"
            value={formData.address}
            onChange={onChange}
            required
          />
        </div>
        <Form.Message
          for="address"
          className="Form__error"
        />
      </label>
      <label htmlFor="city">
        <div>
          <span>Stadt</span>
          <Form.Field
            id="city"
            name="city"
            placeholder="München"
            value={formData.city}
            onChange={onChange}
            required
          />
        </div>
        <Form.Message
          for="city"
          className="Form__error"
        />
      </label>
      <label htmlFor="postalCode">
        <div>
          <span>PLZ</span>
          <Form.Field
            id="postalCode"
            name="postalCode"
            placeholder="12345"
            value={formData.postalCode}
            onChange={onChange}
            required
          />
        </div>
        <Form.Message
          for="postalCode"
          className="Form__error"
        />
      </label>
    </fieldset>
  );
}

export default AddressSection;
