import { glMatrix, mat4, vec3 } from 'gl-matrix';
import range from 'lodash/range';
import createShader from './helper/createShader';
import createProgram from './helper/createProgram';
import createViewProjectionMatrix from './helper/createViewProjectionMatrix';
import { controlModes } from '../state/reducers/editor/controlMode';
import { HEIGHT } from './geometry/cube';
import { DEFAULT_BORDER, GRAVING_MAX_HEIGHT } from '../state/reducers/images/config';

const TESSELATION_LEVEL = 400;

class Gravure {
  constructor(gl) {
    this.gl = gl;
    this.textures = {};
    this.framebuffer = {};
    this.lastWidth = null;
    this.lastHeight = null;
  }

  async init() {
    this.loadGeometry();
    await Promise.all([
      this.loadProgram(),
      this.loadDepthProgram(),
      this.createDepthResources(),
    ]);
  }

  freeResources() {
    const { gl } = this;

    Object.keys(this.textures).forEach((key) => {
      gl.deleteTexture(this.textures[key]);
    });

    Object.keys(this.framebuffer).forEach((key) => {
      gl.deleteFramebuffer(this.framebuffer[key]);
    });
  }

  initIndexBuffer() {
    const indices = new Array(6 * ((TESSELATION_LEVEL) ** 2));

    for (let i = 0; i < TESSELATION_LEVEL; ++i) {
      for (let j = 0; j < TESSELATION_LEVEL; ++j) {
        const topL = i * (TESSELATION_LEVEL + 1) + j;
        const bottomL = (i + 1) * (TESSELATION_LEVEL + 1) + j;
        const topR = i * (TESSELATION_LEVEL + 1) + (j + 1);
        const bottomR = (i + 1) * (TESSELATION_LEVEL + 1) + (j + 1);

        indices[6 * (i * TESSELATION_LEVEL + j) + 0] = bottomL;
        indices[6 * (i * TESSELATION_LEVEL + j) + 1] = topL;
        indices[6 * (i * TESSELATION_LEVEL + j) + 2] = topR;
        indices[6 * (i * TESSELATION_LEVEL + j) + 3] = topR;
        indices[6 * (i * TESSELATION_LEVEL + j) + 4] = bottomR;
        indices[6 * (i * TESSELATION_LEVEL + j) + 5] = bottomL;
      }
    }

    const { gl } = this;
    this.indexBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, this.indexBuffer);
    gl.bufferData(gl.ELEMENT_ARRAY_BUFFER, new Uint32Array(indices), gl.STATIC_DRAW);
  }

  initVertexBuffer() {
    const a = 1.0 / TESSELATION_LEVEL;
    const positions = new Array(3 * ((TESSELATION_LEVEL + 1) ** 2));

    for (let i = 0; i <= TESSELATION_LEVEL; ++i) {
      for (let j = 0; j <= TESSELATION_LEVEL; ++j) {
        positions[3 * (i * (TESSELATION_LEVEL + 1) + j) + 0] = a * i * 2.0 - 1.0;
        positions[3 * (i * (TESSELATION_LEVEL + 1) + j) + 1] = 0.0;
        positions[3 * (i * (TESSELATION_LEVEL + 1) + j) + 2] = a * j * 2.0 - 1.0;
      }
    }

    const { gl } = this;
    this.vertexBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, this.vertexBuffer);
    gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(positions), gl.STATIC_DRAW);
  }

  async loadDepthProgram() {
    const { gl } = this;
    const vertexShader = await createShader(gl, gl.VERTEX_SHADER, 'shader/graving_depth.vert');
    const fragmentShader = await createShader(gl, gl.FRAGMENT_SHADER, 'shader/graving_depth.frag');
    const program = createProgram(gl, fragmentShader, vertexShader);

    this.depthProgramInfo = {
      program,
      attribLocations: {
        vertexPosition: gl.getAttribLocation(program, 'aVertexPosition'),
      },
      uniformLocations: {
        modelViewProjectionMatrix: gl.getUniformLocation(program, 'uMVP'),
        modelMatrix: gl.getUniformLocation(program, 'uModelMatrix'),
        heightMap: gl.getUniformLocation(program, 'uHeightMap'),
        aspectRatio: gl.getUniformLocation(program, 'uAspectRatio'),
        border: gl.getUniformLocation(program, 'uBorder'),
        maxHeight: gl.getUniformLocation(program, 'uMaxHeight'),
        boxHeight: gl.getUniformLocation(program, 'uBoxHeight'),
      },
    };
  }

  async loadProgram() {
    const { gl } = this;
    const vertexShader = await createShader(gl, gl.VERTEX_SHADER, 'shader/gravure.vert');
    const fragmentShader = await createShader(gl, gl.FRAGMENT_SHADER, 'shader/gravure.frag');
    const program = createProgram(gl, fragmentShader, vertexShader);

    this.programInfo = {
      program,
      attribLocations: {
        vertexPosition: gl.getAttribLocation(program, 'aVertexPosition'),
      },
      uniformLocations: {
        projectionMatrix: gl.getUniformLocation(program, 'uProjectionMatrix'),
        viewMatrix: gl.getUniformLocation(program, 'uViewMatrix'),
        modelViewMatrix: gl.getUniformLocation(program, 'uModelViewMatrix'),
        modelMatrix: gl.getUniformLocation(program, 'uModelMatrix'),
        depthMVP: gl.getUniformLocation(program, 'uDepthMVP'),
        woodAtlases: range(1).map(i => gl.getUniformLocation(program, `uAtlas${i}`)),
        normalTexture: gl.getUniformLocation(program, 'uNormalTexture'),
        specularTexture: gl.getUniformLocation(program, 'uSpecularTexture'),
        imageNormalTexture: gl.getUniformLocation(program, 'uImageNormalTexture'),
        shadowMap: gl.getUniformLocation(program, 'uShadowMap'),
        heightMap: gl.getUniformLocation(program, 'uHeightMap'),
        contrast: gl.getUniformLocation(program, 'uContrast'),
        light: gl.getUniformLocation(program, 'uLight'),
        eye: gl.getUniformLocation(program, 'uEye'),
        inCropMode: gl.getUniformLocation(program, 'uInCropMode'),
        aspectRatio: gl.getUniformLocation(program, 'uAspectRatio'),
        innerAspectRatio: gl.getUniformLocation(program, 'uInnerAspectRatio'),
        border: gl.getUniformLocation(program, 'uBorder'),
        maxHeight: gl.getUniformLocation(program, 'uMaxHeight'),
        boxHeight: gl.getUniformLocation(program, 'uBoxHeight'),
      },
    };
  }

  loadGeometry() {
    this.initVertexBuffer();
    this.initIndexBuffer();
  }

  handleResize() {
    this.createDepthResources();
  }

  checkForResize() {
    const { width, height } = this.gl.canvas;
    if (width !== this.depthTextureWidth || height !== this.depthTextureHeight) {
      this.handleResize();
    }
  }

  async createDepthResources() {
    const { gl } = this;

    if (this.textures.depth) gl.deleteTexture(this.textures.depth);
    if (this.framebuffer.depth) gl.deleteFramebuffer(this.framebuffer.depth);

    const { width, height } = gl.canvas;
    this.textures.depth = gl.createTexture();
    gl.bindTexture(gl.TEXTURE_2D, this.textures.depth);
    gl.texImage2D(gl.TEXTURE_2D, 0, gl.DEPTH_COMPONENT, width, height, 0, gl.DEPTH_COMPONENT, gl.UNSIGNED_SHORT, null);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);

    this.framebuffer.depth = gl.createFramebuffer();
    gl.bindFramebuffer(gl.FRAMEBUFFER, this.framebuffer.depth);
    gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.DEPTH_ATTACHMENT, gl.TEXTURE_2D, this.textures.depth, 0);

    this.depthTextureWidth = width;
    this.depthTextureHeight = height;
  }

  renderShadowMap(inputTextures, modelMatrix, light, imageConfig) {
    const { gl } = this;
    const { program, attribLocations, uniformLocations } = this.depthProgramInfo;
    gl.useProgram(program);

    gl.bindFramebuffer(gl.FRAMEBUFFER, this.framebuffer.depth);
    gl.clear(gl.DEPTH_BUFFER_BIT);

    const depthViewMatrix = mat4.create();
    mat4.lookAt(depthViewMatrix, light, vec3.fromValues(0.0, 0.0, 0.0), vec3.fromValues(0.0, 1.0, 0.0));

    const depthProjectionMatrix = mat4.create();
    mat4.perspective(depthProjectionMatrix, glMatrix.toRadian(45), imageConfig.frameAspectRatio, 1, 100);

    const depthMVP = mat4.create();
    mat4.multiply(depthMVP, depthViewMatrix, modelMatrix);
    mat4.multiply(depthMVP, depthProjectionMatrix, depthMVP);

    // buffers

    gl.bindBuffer(gl.ARRAY_BUFFER, this.vertexBuffer);
    gl.vertexAttribPointer(attribLocations.vertexPosition, 3, gl.FLOAT, false, 0, 0);
    gl.enableVertexAttribArray(attribLocations.vertexPosition);

    gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, this.indexBuffer);

    // texture

    gl.activeTexture(gl.TEXTURE0);
    gl.bindTexture(gl.TEXTURE_2D, inputTextures.heightMap);

    // uniforms

    gl.uniformMatrix4fv(uniformLocations.modelViewProjectionMatrix, false, depthMVP);

    gl.uniform1f(uniformLocations.aspectRatio, imageConfig.frameAspectRatio);
    gl.uniform1f(uniformLocations.border, DEFAULT_BORDER);
    gl.uniform1f(uniformLocations.maxHeight, GRAVING_MAX_HEIGHT);
    gl.uniform1f(uniformLocations.boxHeight, HEIGHT);
    gl.uniform1i(uniformLocations.heightMap, 0);

    // draw

    gl.drawElements(gl.TRIANGLES, (TESSELATION_LEVEL ** 2) * 6, gl.UNSIGNED_INT, 0);
    gl.bindFramebuffer(gl.FRAMEBUFFER, null);

    return { depthMVP };
  }

  render(textures, config, imageConfig) {
    if (!textures.heightMap) {
      throw new Error('Missing image processing textures for rendering.');
    }

    if (!textures.woodAtlases) {
      throw new Error('Missing wood textures for rendering.');
    }

    this.checkForResize();

    const { gl } = this;

    const {
      eye,
      light,
      projectionMatrix,
      viewMatrix,
    } = createViewProjectionMatrix(gl, config);

    const modelMatrix = mat4.create();
    mat4.rotate(modelMatrix, modelMatrix, glMatrix.toRadian(config.rotation.x), [0.0, 1.0, 0.0]);
    mat4.rotate(modelMatrix, modelMatrix, glMatrix.toRadian(90 + config.rotation.y), [1.0, 0.0, 0.0]);

    // shadow mapping

    const { depthMVP } = this.renderShadowMap(textures, modelMatrix, light, imageConfig);

    // normal stuff

    const { program, attribLocations, uniformLocations } = this.programInfo;
    gl.useProgram(program);

    const modelViewMatrix = mat4.create();
    mat4.multiply(modelViewMatrix, viewMatrix, modelMatrix);

    // buffers

    gl.bindBuffer(gl.ARRAY_BUFFER, this.vertexBuffer);
    gl.vertexAttribPointer(attribLocations.vertexPosition, 3, gl.FLOAT, false, 0, 0);
    gl.enableVertexAttribArray(attribLocations.vertexPosition);

    gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, this.indexBuffer);

    // texture

    gl.activeTexture(gl.TEXTURE0);
    gl.bindTexture(gl.TEXTURE_2D, textures.heightMap);
    gl.uniform1i(uniformLocations.heightMap, 0);

    gl.activeTexture(gl.TEXTURE1);
    gl.bindTexture(gl.TEXTURE_2D, textures.normalMap);
    gl.uniform1i(uniformLocations.imageNormalTexture, 1);

    gl.activeTexture(gl.TEXTURE2);
    gl.bindTexture(gl.TEXTURE_2D, this.textures.depth);
    gl.uniform1i(uniformLocations.shadowMap, 2);

    textures.woodAtlases.forEach((texture, index) => {
      gl.activeTexture(gl.TEXTURE3 + index);
      gl.bindTexture(gl.TEXTURE_2D, texture);
      gl.uniform1i(uniformLocations.woodAtlases[index], 3 + index);
    });

    // uniforms

    gl.uniformMatrix4fv(uniformLocations.depthMVP, false, depthMVP);
    gl.uniformMatrix4fv(uniformLocations.projectionMatrix, false, projectionMatrix);
    gl.uniformMatrix4fv(uniformLocations.modelViewMatrix, false, modelViewMatrix);
    gl.uniformMatrix4fv(uniformLocations.viewMatrix, false, viewMatrix);
    gl.uniformMatrix4fv(uniformLocations.modelMatrix, false, modelMatrix);

    gl.uniform1f(
      uniformLocations.inCropMode,
      config.controlMode === controlModes.CROP ? 1.0 : 0.0,
    );

    gl.uniform1f(uniformLocations.innerAspectRatio, imageConfig.innerAspectRatio);
    gl.uniform1f(uniformLocations.aspectRatio, imageConfig.frameAspectRatio);
    gl.uniform1f(uniformLocations.border, DEFAULT_BORDER);
    gl.uniform1f(uniformLocations.maxHeight, GRAVING_MAX_HEIGHT);
    gl.uniform1f(uniformLocations.boxHeight, HEIGHT);

    gl.uniform3fv(uniformLocations.eye, eye);
    gl.uniform3fv(uniformLocations.light, light);

    gl.drawElements(gl.TRIANGLES, (TESSELATION_LEVEL ** 2) * 6, gl.UNSIGNED_INT, 0);
  }
}

export default Gravure;
