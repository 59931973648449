import React from 'react';
import PaymentMethodSelectorItem from './PaymentMethodSelectorItem';
import './PaymentMethodSelector.scss';

function PaymentMethodSelector({ activeMethod, onChange }) {
  return (
    <nav>
      <ul className="PaymentMethodSelector">
        <PaymentMethodSelectorItem
          id="paypal"
          name="PayPal"
          onChange={onChange}
          isActive={activeMethod === 'paypal'}
        />
        <PaymentMethodSelectorItem
          id="card"
          name="Kreditkarte"
          onChange={onChange}
          isActive={activeMethod === 'card'}
        />
        <PaymentMethodSelectorItem
          id="giropay"
          name="GiroPay"
          onChange={onChange}
          isActive={activeMethod === 'giropay'}
        />
        <PaymentMethodSelectorItem
          id="sofort"
          name="Sofort"
          onChange={onChange}
          isActive={activeMethod === 'sofort'}
        />
      </ul>
    </nav>
  );
}

export default PaymentMethodSelector;
