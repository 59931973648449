import * as yup from 'yup';

export default yup.object({
  name: yup.string()
    .required('Bitte geben Sie Ihren Namen ein.'),
  email: yup.string()
    .email('Bitte geben Sie eine gültige E-Mail-Adresse an.')
    .required('Bitte geben Sie Ihre E-Mail-Adresse an.'),
  address: yup.string()
    .matches(/[\w ]+ \d+/, 'Bitte geben Sie Straße und Hausnummer an.')
    .required('Bitte geben Sie Straße und Hausnummer an.'),
  city: yup.string()
    .required('Bitte geben Sie Ihre Stadt an.'),
  postalCode: yup.string()
    .matches(/\d{5}/, 'Bitte geben Sie eine gültige Postleitzahl an.')
    .required('Bitte geben Sie Ihre Postleitzahl an.'),
});
