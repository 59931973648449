import { dataURLToBlob } from 'blob-util';
import api from '../config/api';

class UploadService {
  static async uploadProcessImages({ original, processed }): Promise<string> {
    const data = new FormData();
    data.append('original_image', dataURLToBlob(original));
    data.append('processed_image', dataURLToBlob(processed));

    const response = await api.post('/images', data);
    return response.data;
  }
}

export default UploadService;
