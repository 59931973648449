import { useMotion, useMedia } from 'react-use';
import { connect } from 'react-redux';

const TRESHOLD = 0.5;
const SPEED_TABLET = 0.1;
const SPEED_PHONE = 0.02;

function DeviceMotionListener(props) {
  const isPhone = useMedia('(max-width: 480px)');
  const { rotationRate } = useMotion();

  function rotate() {
    const rateX = Math.round(rotationRate.beta * 100) / 100;
    const rateY = Math.round(rotationRate.alpha * 100) / 100;
    const SPEED = isPhone ? SPEED_PHONE : SPEED_TABLET;
    const stepX = Math.abs(rateX) > TRESHOLD ? -rateX * SPEED : 0;
    const stepY = Math.abs(rateY) > TRESHOLD ? -rateY * SPEED : 0;

    if (stepX === 0 && stepY === 0) return;

    props.dispatch({
      type: 'ROTATE_BY',
      stepX,
      stepY,
    });
  }

  requestAnimationFrame(rotate);

  return null;
}

export default connect()(DeviceMotionListener);
