import React from 'react';
import { connect } from 'react-redux';
import Sidebar from '../../utility/Sidebar';
import DiscountCode from './DiscountCode';
import PRICING, { getTotalPrize } from '../../../config/pricing';

function decimal2(i) {
  return Math.round(i * 100) / 100;
}

function CostSection(props) {
  const total = getTotalPrize(props.items);
  const absoluteDiscount = decimal2(total * (props.discount / 100));
  const totalMinusDiscount = decimal2(total - absoluteDiscount);

  const itemsAmount = props.items.length;

  return (
    <Sidebar.Section>
      <DiscountCode />
      <div className="Sidebar__line-item">
        <span className="Sidebar__line-item__label">
          {itemsAmount > 1 && 'Erste '}
          Gravur
        </span>
        <span className="Sidebar__line-item__price">
          {PRICING.base.toString()}
          {' '}
          &euro;
        </span>
      </div>
      {itemsAmount > 1 && (
        <div className="Sidebar__line-item">
          <span className="Sidebar__line-item__label">weitere Gravuren</span>
          <span className="Sidebar__line-item__price">
            {itemsAmount - 1} x {PRICING.subsequent}
            {' '}
            &euro;
          </span>
        </div>
      )}
      {!!props.discount && (
        <div className="Sidebar__line-item">
          <span className="Sidebar__line-item__label">
            Rabatt
            {' '}
            ({props.discount}%)
          </span>
          <span className="Sidebar__line-item__price">
            -
            {absoluteDiscount}
            {' '}
            &euro;
          </span>
        </div>
      )}
      <div className="Sidebar__line-item">
        <span className="Sidebar__line-item__label">Versand</span>
        <span className="Sidebar__line-item__price">kostenlos</span>
      </div>
      <hr className="Sidebar__divider" />
      <div className="Sidebar__line-item">
        <span className="Sidebar__line-item__label">Gesamt</span>
        <span className="Sidebar__line-item__price">
          {totalMinusDiscount}
          {' '}
          &euro;
        </span>
      </div>
    </Sidebar.Section>
  );
}

const mapStateToProps = state => ({
  items: state.images.items,
  discount: state.discount ? state.discount.discount : 0,
});

export default connect(mapStateToProps)(CostSection);
