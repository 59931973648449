import createShader from '../helper/createShader';
import createProgram from '../helper/createProgram';
import squareXY from '../geometry/squareXY';

class ImageToHeightMap {
  constructor(gl, image) {
    this.gl = gl;
    this.width = image.width;
    this.pixels = null;
  }

  async init() {
    this.loadGeometry();
    await this.loadProgram();
  }

  freeResources() {
    const { gl, programInfo: { program } } = this;

    gl.getAttachedShaders(program).forEach((shader) => {
      gl.deleteShader(shader);
    });

    gl.deleteProgram(program);
  }

  async loadProgram() {
    const { gl } = this;
    const vertexShader = await createShader(gl, gl.VERTEX_SHADER, 'shader/image_to_height_map.vert');
    const fragmentShader = await createShader(gl, gl.FRAGMENT_SHADER, 'shader/image_to_height_map.frag');
    const program = createProgram(gl, fragmentShader, vertexShader);

    this.programInfo = {
      program,
      attribLocations: {
        vertexPosition: gl.getAttribLocation(program, 'aVertexPosition'),
      },
      uniformLocations: {
        image: gl.getUniformLocation(program, 'uImage'),
        contrast: gl.getUniformLocation(program, 'uContrast'),
        brightness: gl.getUniformLocation(program, 'uBrightness'),
      },
    };
  }

  loadGeometry() {
    const { gl } = this;
    this.vertexBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, this.vertexBuffer);
    gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(squareXY.vertices), gl.STATIC_DRAW);
  }

  render(inTexture, outFramebuffer, imageConfig) {
    const { gl } = this;
    const { program, attribLocations, uniformLocations } = this.programInfo;
    gl.useProgram(program);

    gl.activeTexture(gl.TEXTURE0);
    gl.bindTexture(gl.TEXTURE_2D, inTexture);

    // uniforms

    gl.uniform1i(uniformLocations.image, 0);
    gl.uniform1f(uniformLocations.contrast, imageConfig.contrast);
    gl.uniform1f(uniformLocations.brightness, imageConfig.brightness);

    // draw

    const { width } = this;
    const height = width / imageConfig.innerAspectRatio;

    gl.bindBuffer(gl.ARRAY_BUFFER, this.vertexBuffer);
    gl.vertexAttribPointer(attribLocations.vertexPosition, 3, gl.FLOAT, false, 0, 0);
    gl.enableVertexAttribArray(attribLocations.vertexPosition);

    gl.bindFramebuffer(gl.FRAMEBUFFER, outFramebuffer);
    gl.viewport(0, 0, width, height);
    gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4);

    this.pixels = new Uint8Array(width * height * 4);
    gl.readPixels(0, 0, width, height, gl.RGBA, gl.UNSIGNED_BYTE, this.pixels);
  }
}

export default ImageToHeightMap;
