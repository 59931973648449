import api from '../config/api';

class DiscountCodeService {
  static validate(inputCode: string): Promise<false | Object> {
    return async (dispatch) => {
      try {
        const response = await api.get(`/discount_codes/${inputCode}/validate`);
        const { code, discount } = response.data;

        dispatch({
          type: 'SET_DISCOUNT',
          code,
          discount,
        });

        return true;
      } catch (error) {
        dispatch({ type: 'REMOVE_DISCOUNT' });
      }

      return false;
    };
  }
}

export default DiscountCodeService;
