import {
  DEFAULT_BRIGHTNESS,
  DEFAULT_CONTRAST,
  updateActiveImage,
  minPositions,
  minZoom,
  MAX_ZOOM,
  innerAspectRatio as getInnerAspectRatio,
  DEFAULT_ASPECT_RATIO,
  DEFAULT_WIDTH,
  DEFAULT_HEIGHT,
} from './config';
import defaultImage from '../../defaultImage';


const initialState = {
  isDefault: true,
  active: 0,
  items: [defaultImage],
};

function images(state = initialState, action) {
  switch (action.type) {
    case 'ADD_IMAGE':
      return {
        ...state,
        isDefault: false,
        active: state.isDefault ? 0 : state.items.length,
        items: [...(state.isDefault ? [] : state.items), {
          src: action.src,
          width: action.width,
          height: action.height,
          aspectRatio: action.width / action.height,
          // frame
          frameWidth: DEFAULT_WIDTH,
          frameHeight: DEFAULT_HEIGHT,
          frameAspectRatio: DEFAULT_ASPECT_RATIO,
          innerAspectRatio: getInnerAspectRatio(DEFAULT_WIDTH, DEFAULT_HEIGHT),
          // processing
          brightness: DEFAULT_BRIGHTNESS,
          contrast: DEFAULT_CONTRAST,
          // displacement
          position: { x: 0, y: 0 },
          zoom: minZoom(DEFAULT_ASPECT_RATIO, action.width / action.height),
        }],
      };
    case 'REMOVE_IMAGE': {
      const nextItems = state.items.filter((item, index) => index !== action.index);

      if (nextItems.length === 0) {
        return initialState;
      }

      return {
        ...state,
        active: 0,
        items: nextItems,
      };
    }
    case 'SET_ACTIVE_IMAGE':
      return {
        ...state,
        active: action.index,
      };
    case 'SET_ASPECT_RATIO':
      return updateActiveImage(state, (image) => {
        const frameAspectRatio = action.width / action.height;
        const innerAspectRatio = getInnerAspectRatio(action.width, action.height);

        return {
          frameWidth: action.width,
          frameHeight: action.height,
          frameAspectRatio,
          innerAspectRatio,
          position: { x: 0, y: 0 },
          zoom: minZoom(innerAspectRatio, image.aspectRatio),
        };
      });
    // processing
    case 'SET_BRIGHTNESS':
      return updateActiveImage(state, { brightness: action.value });
    case 'SET_CONTRAST':
      return updateActiveImage(state, { contrast: action.value });

    // displacement
    case 'ZOOM_IMAGE_BY':
      return updateActiveImage(state, (image) => {
        const nextZoom = action.step > 0
          ? Math.min(image.zoom + action.step, MAX_ZOOM)
          : Math.max(image.zoom + action.step, minZoom(image.innerAspectRatio, image.aspectRatio));
        const min = minPositions(nextZoom, image.innerAspectRatio, image.aspectRatio);

        return {
          zoom: nextZoom,
          position: {
            x: Math.max(image.position.x, min.x),
            y: Math.max(image.position.y, min.y),
          },
        };
      });
    case 'MOVE_IMAGE_BY':
      return updateActiveImage(state, (image) => {
        const min = minPositions(image.zoom, image.innerAspectRatio, image.aspectRatio);

        return {
          position: {
            x: action.dx > 0
              ? Math.min(image.position.x + action.dx, 0)
              : Math.max(image.position.x + action.dx, min.x),
            y: action.dy > 0
              ? Math.min(image.position.y + action.dy, 0)
              : Math.max(image.position.y + action.dy, min.y),
          },
        };
      });
    default:
      return state;
  }
}

export default images;
