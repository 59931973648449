import PRICING from '../config/pricing';

function decimal2(i: number): number {
  return Math.round(i * 100) / 100;
}

class PriceCalculator {
  itemsAmount: number;

  discount: string;

  setItems(items): this {
    this.itemsAmount = items.length;
    return this;
  }

  setDiscount(discount: number): this {
    this.discount = discount;
    return this;
  }

  getTotal(): number {
    if (this.itemsAmount === 0) return 0;

    // base price
    let total = PRICING.base;

    // subsequent price
    total += (this.itemsAmount - 1.0) * PRICING.subsequent;

    // discount
    if (this.discount) {
      total -= decimal2(total * (this.discount / 100.0));
    }

    return decimal2(total);
  }
}

export default PriceCalculator;
