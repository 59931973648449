function setupGl(gl) {
  gl.clearColor(1.0, 1.0, 1.0, 1.0);
  gl.clearDepth(1.0);
  gl.enable(gl.DEPTH_TEST);
  gl.depthFunc(gl.LEQUAL);
  gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);

  gl.enable(gl.CULL_FACE);
  gl.cullFace(gl.BACK);

  // extensions
  gl.getExtension('OES_standard_derivatives');
  gl.getExtension('EXT_shader_texture_lod');
  gl.getExtension('WEBGL_depth_texture');
  gl.getExtension('OES_element_index_uint');
}

export default setupGl;
