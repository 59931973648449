async function createShader(gl, type, path) {
  const shader = gl.createShader(type);
  const source = await fetch(path).then(response => response.text());

  gl.shaderSource(shader, source);
  gl.compileShader(shader);

  if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
    throw new Error(gl.getShaderInfoLog(shader));
  }

  return shader;
}

export default createShader;
