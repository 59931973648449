import React from 'react';
import classNames from 'classnames';

function PaymentMethodSelectorItem({
  onChange,
  id,
  name,
  isActive,
}) {
  const className = classNames('PaymentMethodSelector__item', {
    '-active': isActive,
  });

  return (
    <li className={className}>
      <label htmlFor={`payment-${id}`}>
        <input
          type="radio"
          name="payment"
          id={`payment-${id}`}
          value={id}
          onChange={onChange}
          checked={isActive}
        />
        {name}
      </label>
    </li>
  );
}

export default PaymentMethodSelectorItem;
