/* eslint-disable indent, no-multi-spaces */

export default {
  vertices: [
    -1.0, -1.0,  0.0,
     1.0, -1.0,  0.0,
    -1.0,  1.0,  0.0,
     1.0,  1.0,  0.0,
  ],
};
