import ImageProcessor from '../../rendering/image_processing/ImageProcessor';

async function getProcessedImage(imageConfig) {
  const canvas = document.createElement('canvas');
  const gl = canvas.getContext('webgl');

  const imageProcessor = new ImageProcessor(gl);
  await imageProcessor.init(imageConfig.src);
  imageProcessor.render(imageConfig);
  const processedImage = imageProcessor.extractImage();
  imageProcessor.freeResources();

  return processedImage;
}

export default getProcessedImage;
