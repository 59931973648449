import React from 'react';
import shortid from 'shortid';
import classNames from 'classnames';
import { connect } from 'react-redux';
import ComputerImportButton from './ComputerImportButton';
import InstagramImportButton from './InstagramImportButton';
import ImagesListItem from './ImagesListItem';
import './ImportControls.scss';

function ImportControls(props) {
  const className = classNames('ImportControls', {
    '-with-image': !!props.hasActiveImage,
  });

  return (
    <div className={className}>
      {props.hasActiveImage ? (
        <div className="ImportControls__list">
          {props.images.map((image, index) => (
            <ImagesListItem
              key={shortid.generate()}
              index={index}
              image={image}
            />
          ))}
        </div>
      ) : (
        <h2 className="ImportControls__title">
          Dein Bild auswählen
        </h2>
      )}
      <div className="ImportControls__buttons">
        {props.hasActiveImage && (
          <span className="ImportControls__buttons__title">
            Bild hinzufügen
          </span>
        )}
        <div className="ImportControls__buttons__buttons">
          <ComputerImportButton />
          <InstagramImportButton />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  hasActiveImage: !state.images.isDefault,
  images: state.images.items,
  image: state.images.items[state.images.active],
});

export default connect(mapStateToProps)(ImportControls);
