import React from 'react';
import { Switch, Route } from 'react-router-dom';
import PayPalConfirmation from './confirmation/PayPalConfirmation';
import StripeSourceConfirmation from './confirmation/StripeSourceConfirmation';
import StripePaymentIntentConfirmation from './confirmation/StripePaymentIntentConfirmation';
import Editor from './canvas/Editor';
import './App.scss';

function App() {
  return (
    <div className="App">
      <Switch>
        <Route path="/confirmation/s/source" component={StripeSourceConfirmation} />
        <Route path="/confirmation/s/payment_intent" component={StripePaymentIntentConfirmation} />
        <Route path="/confirmation/p" component={PayPalConfirmation} />
        <Route path="/" component={Editor} />
      </Switch>
    </div>
  );
}

export default App;
