import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import DiscountCodeService from '../../../services/DiscountCodeService';
import './DiscountCode.scss';

function DiscountCode(props) {
  const { inputCode } = props;
  const [isValidating, setIsValidating] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const className = classNames('DiscountCode', {
    'DiscountCode--valid': isValid,
    'DiscountCode--invalid': inputCode.length >= 6 && !isValid,
    'DiscountCode--validating': isValidating,
  });

  async function validateCode() {
    if (inputCode.length < 6) {
      props.dispatch({ type: 'REMOVE_DISCOUNT' });
      setIsValid(false);
      return;
    }

    setIsValidating(true);
    const result = await props.dispatch(DiscountCodeService.validate(inputCode));
    setIsValidating(false);
    setIsValid(result);
  }

  useEffect(() => {
    validateCode();
  }, [inputCode]);

  function handleChange(e) {
    props.dispatch({
      type: 'SET_CHECKOUT_FORM_DATA',
      discountCode: e.target.value.toUpperCase(),
    });
  }

  return (
    <input
      type="text"
      placeholder="RABATT-CODE"
      className={className}
      value={inputCode}
      onChange={handleChange}
      maxLength={9}
    />
  );
}

const mapStateToProps = state => ({
  inputCode: state.checkoutForm.discountCode,
});

export default connect(mapStateToProps)(DiscountCode);
