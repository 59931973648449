import React from 'react';
import StartSection from './sections/StartSection';
import HomeFooter from './sections/HomeFooter';
import './Home.scss';

function Home() {
  return (
    <main className="Home">
      <StartSection />
      <HomeFooter />
    </main>
  );
}

export default Home;
