import React, { useState } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import InstagramSelectionItem from './InstagramSelectionItem';
import Modal from '../../utility/Modal';
import instagramIcon from '../../../assets/icons/instagram.png';
import './InstagramSelectionModal.scss';
import Button from '../../utility/buttons/Button';
import ActivityIndicator from '../../utility/ActivityIndicator';

function readFile(data) {
  const promise = new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(data);
    reader.onload = () => resolve(reader.result);
  });

  return promise;
}

function InstagramSelectionModal(props) {
  const { images } = props;
  const [selected, setSelected] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  function handleItemClick(imageId) {
    if (selected.includes(imageId)) {
      setSelected(selected.filter(id => imageId !== id));
    } else {
      setSelected([...selected, imageId]);
    }
  }

  async function handleSubmit() {
    setIsSubmitting(true);

    const selectedImages = images.filter(({ id }) => selected.includes(id));

    await Promise.all(selectedImages.map(async (image) => {
      const { url, width, height } = image.images.standard_resolution;
      const response = await axios.get(url, { responseType: 'blob' });
      const src = await readFile(response.data);

      props.dispatch({
        type: 'ADD_IMAGE',
        src,
        width,
        height,
      });
    }));

    setSelected([]);
    setIsSubmitting(false);
    props.onClose();
  }

  return (
    <Modal
      overlayClassName="InstagramSelectionModal__overlay"
      className="InstagramSelectionModal__content"
      isOpen={props.isOpen}
      onRequestClose={props.onClose}
    >
      <h2 className="InstagramSelectionModal__title">
        <img alt="Instagram" src={instagramIcon} />
        Von Instagram importieren
      </h2>
      <p className="InstagramSelectionModal__disclaimer">
        Wir speichern keine deine Bilder, die du nicht zur Bestellung aufgegeben hast.
      </p>
      <div className="InstagramSelectionModal__list">
        {images.map(image => (
          <InstagramSelectionItem
            image={image}
            key={image.id}
            isSelected={selected.includes(image.id)}
            onClose={props.onClose}
            onClick={handleItemClick}
          />
        ))}
      </div>
      <Button
        className="InstagramSelectionModal__submit-button"
        onClick={handleSubmit}
        size={3}
        disabled={selected.length === 0}
      >
        Fertig
        {isSubmitting && <ActivityIndicator />}
      </Button>
    </Modal>
  );
}

export default connect()(InstagramSelectionModal);
