import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { IoIosCheckmarkCircle, IoIosRadioButtonOff } from 'react-icons/io';

function InstagramSelectionItem(props) {
  const { image, isSelected, onClick } = props;

  function handleClick() {
    onClick(image.id);
  }

  return (
    <button
      type="button"
      onClick={handleClick}
      className="InstagramSelectionModal__list__item"
    >
      <div
        className={classNames('InstagramSelectionModal__list__item__selection', {
          '-selected': isSelected,
        })}
      >
        {isSelected ? (
          <IoIosCheckmarkCircle />
        ) : (
          <IoIosRadioButtonOff />
        )}
      </div>
      <img
        alt="Instagram Post"
        src={image.images.low_resolution.url}
      />
      <div className="InstagramSelectionModal__list__item__overlay">
        {isSelected ? 'Abwählen' : 'Auswählen'}
      </div>
    </button>
  );
}

export default connect()(InstagramSelectionItem);
