import api from '../config/api';

class OrderService {
  static async create(data: Object): Promise<false | Object> {
    const response = await api.post('/orders', data);
    return response.data;
  }
}

export default OrderService;
