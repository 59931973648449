import resizeImage from 'resize-image';

const IMAGE_MAX_SIZE = 1000;

function shrinkImage(image) {
  let { width, height } = image;
  const aspectRatio = width / height;

  if (width > height) {
    width = IMAGE_MAX_SIZE;
    height = Math.round(IMAGE_MAX_SIZE / aspectRatio);
  } else {
    width = Math.round(IMAGE_MAX_SIZE * aspectRatio);
    height = IMAGE_MAX_SIZE;
  }

  const src = resizeImage.resize(image, width, height, resizeImage.JPEG);

  return { width, height, src };
}

function compress(image, quality) {
  const canvas = document.createElement('canvas');
  canvas.width = image.naturalWidth;
  canvas.height = image.naturalHeight;

  const ctx = canvas.getContext('2d');
  ctx.drawImage(image, 0, 0);

  const compressedImage = new Image();
  compressedImage.src = canvas.toDataURL('image/jpeg', quality / 100);

  return compressedImage;
}

export default function preProcessImage(image) {
  let { width, height, src } = image;

  ({ src } = compress(image, 80));

  if (width > IMAGE_MAX_SIZE || height > IMAGE_MAX_SIZE) {
    ({ width, height, src } = shrinkImage(image));
  }

  return { width, height, src };
}
