import React from 'react';
import { Spinner } from 'react-activity';
import 'react-activity/dist/react-activity.css';

function ActivityIndicator(props) {
  return (
    <Spinner
      color="#727981"
      size={16 * props.size}
      speed={1}
      className={props.className}
      animating
    />
  );
}

ActivityIndicator.defaultProps = {
  size: 1,
  className: '',
};

export default ActivityIndicator;
