import axios from 'axios';
import configureAxiosCaseManager from './configureAxiosCaseManager';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

configureAxiosCaseManager(api);

export default api;
