import React from 'react';
import { useMedia } from 'react-use';
import StartSectionSlider from './StartSectionSlider';
import StartSectionButton from './StartSectionButton';
import './StartSection.scss';

function StartSection() {
  const isPhone = useMedia('(max-width: 720px)');

  return (
    <section className="StartSection">
      <content>
        <h1>
          Gravier dein
          <br />
          Lieblingsbild
        </h1>
        <h2>
          Holzgravuren im Postkarten-Format
        </h2>
        <p>
          Verewige ein persönliches Bild auf Holz.
          Unsere Vorschau zeigt dir, wie deine Gravur aussehen wird.
        </p>
        {!isPhone && <StartSectionButton />}
      </content>
      <StartSectionSlider />
      {isPhone && <StartSectionButton />}
    </section>
  );
}

export default StartSection;
