import React from 'react';
import classNames from 'classnames';
import SidebarSection from './SidebarSection';
import './Sidebar.scss';

function Sidebar(props) {
  const className = classNames('Sidebar', props.className);

  return (
    <aside className={className}>
      {props.children}
    </aside>
  );
}

Sidebar.defaultProps = {
  className: '',
};

Sidebar.Section = SidebarSection;

export default Sidebar;
